import './style/pdfViewer.scss';

import React, {ReactElement, useContext, useEffect} from 'react';
import {PDFViewer} from "@react-pdf/renderer";

import {DvrdButton} from '@dvrd/dvr-controls';

import {setPageTitle, toMoment} from "../../util/utils";
import {generateAbsenceScheme, generateGameSchemes, generateRoundScheme, generateTotalsScheme} from "./pdfUtil";
import {TableSorting} from "../../util/interfaces";
import {AppContext} from "../context/appContext";
import {Season} from "../../models/seasonModel";
import {useNavigate} from "react-router-dom";

export default function PdfViewer() {
    const context = useContext(AppContext);
    const navigate = useNavigate();

    function getDocument(): ReactElement | null {
        const params = new URLSearchParams(location.search), doc = params.get('doc');
        if (doc === undefined || doc === null) return null;
        const season = context.seasonContext.season || new Season({contestants: []});
        switch (doc) {
            case 'speelschema':
                const amount = Number(params.get('aantal') || '4');
                const contestant = params.get('deelnemer')!;
                const contestantNumber = params.get('nummer')!;
                return generateGameSchemes(amount, contestant, contestantNumber);
            case 'totaalscore':
                const sorting: TableSorting = (params.get('sort') || TableSorting.NUMBER) as TableSorting;
                return generateTotalsScheme(season.contestants, sorting);
            case 'ronde':
                return generateRoundScheme(season);
            case 'aanwezigheid':
                const week = Number(params.get('week')) || toMoment('').week() + 1;
                return generateAbsenceScheme(season, week);
        }
        return null;
    }

    useEffect(() => {
        const params = new URLSearchParams(location.search), doc = params.get('doc');
        if (doc === undefined || doc === null) return;
        let pageTitle = 'Schema';
        switch (doc) {
            case 'speelschema':
                pageTitle = 'Speelschema';
                break;
            case 'totaalscore':
                pageTitle = 'Totaalscore';
                break;
            case 'ronde':
                pageTitle = 'Ronde scores';
                break;
            case 'aanwezigheid':
                pageTitle = 'Aanwezigheid';
                break;
        }
        setPageTitle(pageTitle);
    }, []);

    function onClickBack() {
        navigate(-1);
    }

    const doc = getDocument();
    if (doc === null) return null;
    return (
        <div className='pdfViewerContainer'>
            <DvrdButton onClick={onClickBack} label='Terug' className='backButton'/>
            <PDFViewer style={{height: '100%'}}>
                {doc}
            </PDFViewer>
        </div>
    )
}