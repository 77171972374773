import * as React from 'react';
import moment, {Moment} from 'moment';
import Cookies from 'js-cookie';

import {DialogActionShape} from '@dvrd/dvr-controls';

import {dispatchCustomEvent} from "./eventDispatcher";
import {ENTER_CODE, ESCAPE_CODE} from "./constants";
import {EventType} from "./interfaces";
import {Season} from "../models/seasonModel";
import {Contestant} from "../models/contestantModel";
import {User} from "../models/userModel";

export const getSetting = (name: string) => {
    // @ts-ignore
    const settings = window.settings;
    if (settings.hasOwnProperty(name))
        return settings[name];
    warn(`Global settings does not contain a value for '${name}'`);
};

export const getMode = (): string => {
    return getSetting('mode');
};

export const log = (message: any, obj?: any, force: boolean = false) => {
    if (getMode() === 'dev' || force) {
        if (isNotNull(obj)) console.log(message, obj);
        else console.log(message);
    }
};

export const warn = (message: string | number | boolean | object, obj?: | any, force: boolean = false) => {
    if (getMode() === 'dev' || force)
        if (isNotNull(obj)) console.warn(message, obj);
        else console.warn(message);
};

export const error = (message: string | number | boolean, obj?: any, force: boolean = false) => {
    if (getMode() === 'dev' || force)
        if (isNotNull(obj)) console.error(message, obj);
        else console.error(message);
};

export const debug = (message: string | number | boolean | object, obj?: any, force: boolean = false) => {
    if (getMode() === 'dev' || force)
        if (isNotNull(obj)) console.debug(message, obj);
        else console.debug(message);
};

export const isNotNull = (obj: any): boolean => {
    if (obj === undefined || obj === null)
        return false;
    return !(obj.hasOwnProperty('length') && obj.length === 0);
};

export const isNull = (obj: any): boolean => !isNotNull(obj);

export const MOMENT_FORMATS: string[] = [
    'MM/DD/YYYY HH:mm',
    'MM-DD-YYYY HH:mm',
    'DD/MM/YYYY HH:mm',
    'DD-MM-YYYY HH:mm',
    'YYYY-MM-DD HH:mm',
    'YYYY/MM/DD HH:mm',
    'DD/MM/YYYYTHH:mm',
    'DD-MM-YYYYTHH:mm',
    'YYYY-MM-DDTHH:mm',
    'YYYY/MM/DDTHH:mm',
    'YYYY-MM-DD',
    'YYYY/MM/DD',
    'MM-DD-YYYY',
    'MM/DD/YYYY',
    'DD-MM-YYYY',
    'DD/MM/YYYY',
];

export const toMoment = (dateString: string, formats: string[] = MOMENT_FORMATS): Moment => {
    if (isNull(dateString)) return moment();
    if (moment.isMoment(dateString)) return dateString;
    return moment(dateString, formats);
};

export const voidFunction = () => null;

export const stringStartsWith = (string: string, value: string): boolean => {
    value = value.trim().toLowerCase();
    const valueLength = value.length;
    return string.toLowerCase().slice(0, valueLength) === value;
};

export const stringContains = (string: string, value: string): boolean =>
    string.toLowerCase().includes(value.toLowerCase());

// noinspection DuplicatedCode
export const hasHover = (element: HTMLElement): boolean => {
    if (isNotNull(element) && element.parentElement !== null) {
        return element.parentElement.querySelector(':hover') === element;
    }
    return false;
};

// noinspection DuplicatedCode,JSUnusedGlobalSymbols
export const pxToRem = (pxValue: number) => {
    let htmlElement = document.getElementsByTagName('html')[0];
    let fontSize: number | string = window.getComputedStyle(htmlElement, null).getPropertyValue('font-size');
    fontSize = parseInt(fontSize.slice(0, fontSize.length - 2), 10);
    return pxValue / fontSize;
};

// noinspection JSUnusedGlobalSymbols
export const remToPx = (remValue: number) => {
    let htmlElement = document.getElementsByTagName('html')[0];
    let fontSize: number | string = window.getComputedStyle(htmlElement, null).getPropertyValue('font-size');
    fontSize = parseInt(fontSize.slice(0, fontSize.length - 2), 10);
    return remValue * fontSize;
};

export const PASSWORD_RULES = {
    MIN_LENGTH: 8, MAX_LENGTH: 24, CONTAIN_NUMBER: true, CONTAIN_SYMBOL: true, CONTAIN_CAPITAL: true,
};

interface Validation {
    MIN_LENGTH: boolean,
    MAX_LENGTH: boolean,
    CONTAIN_NUMBER: boolean,
    CONTAIN_CAPITAL: boolean,
    CONTAIN_SYMBOL: boolean,
}

export const validatePassword = (password: string): Validation => {
    const {MIN_LENGTH, MAX_LENGTH, CONTAIN_CAPITAL, CONTAIN_NUMBER, CONTAIN_SYMBOL} = PASSWORD_RULES;
    return {
        MIN_LENGTH: password.length >= MIN_LENGTH,
        MAX_LENGTH: password.length <= MAX_LENGTH,
        CONTAIN_NUMBER: !CONTAIN_NUMBER || /\d/.test(password),
        CONTAIN_CAPITAL: !CONTAIN_CAPITAL || /[A-Z]/.test(password),
        CONTAIN_SYMBOL: !CONTAIN_SYMBOL || /[!.\/?@#$%^\\&*(),<>`~\-_=+]/.test(password)
    };
};

export let navigationData: any = null;
export const navigate = (url: string, data?: any, stripQuery: boolean = true) => {
    navigationData = data;
    let navigator = document.getElementById('appNavigator');
    if (navigator !== null) {
        navigator.dataset.url = url;
        if (stripQuery) {
            navigator.dataset.strip = '1';
        }
        navigator.click();
    }
};

let jwtToken: string | null = null;
export const getJwt = (): string | null => {
    if (isNotNull(jwtToken)) return jwtToken;

    const jwt = getJwtFromStorage();
    if (isNotNull(jwt)) return jwt;

    const cookieJwt = Cookies.get('jwt_token');
    if (cookieJwt !== null && cookieJwt !== undefined)
        return cookieJwt;

    return null;
};

export const setJwt = (token: string | null, preserveOnNull: boolean = false) => {
    jwtToken = token;
    if (token !== null && token !== undefined)
        Cookies.set('jwt_token', token);
    else if (!preserveOnNull) removeJwtCookie();
    setJwtToStorage(token, preserveOnNull);
};

const setJwtToStorage = (token: string | null, preserveOnNull: boolean = false) => {
    if ((token === null || token === undefined) && !preserveOnNull) removeJwtFromStorage();
    else if (token !== null && token !== undefined) {
        debug('Saving jwt to localStorage');
        try {
            if (window.localStorage)
                window.localStorage.setItem('jwt_token', JSON.stringify(token));
            else debug('Localstorage not supported');
        } catch {
            debug('Localstorage not supported');
            // Localstorage not supported
        }
    }
};

const removeJwtFromStorage = () => {
    try {
        if (window.localStorage) {
            debug('Removing jwt from localStorage');
            window.localStorage.removeItem('jwt_token');
        } else debug('Localstorage not supported');
    } catch {
        // Localstorage not supported
    }
};

const removeJwtCookie = () => {
    Cookies.remove('jwt_token', {domain: '.finbase.com', path: '/'});
    Cookies.remove('jwt_token');
};

const getJwtFromStorage = (): string | null => {
    try {
        if (window.localStorage) {
            const item: string | null = window.localStorage.getItem('jwt_token');
            if (item === null) return null;
            return JSON.parse(item);
        } else debug('Localstorage not supported');
        return null;
    } catch {
        return null;
    }
};


export const enterPressed = (evt: React.KeyboardEvent | KeyboardEvent) => {
    return (evt.key && evt.key === 'Enter') || evt.keyCode === ENTER_CODE;
};

export const escapePressed = (evt: React.KeyboardEvent | KeyboardEvent) => (evt.key && ['Escape', 'Esc'].includes(evt.key)) || evt.keyCode === ESCAPE_CODE;

// =======================================
// EVENTS
// =======================================
export const updateSeasonScores = (season: Season) => {
    dispatchCustomEvent('onOpenSeasonDetail', season);
};

export const setPageTitle = (title: string) => {
    // Use a 0ms timeout te support page loads.
    window.setTimeout(() => {
        dispatchCustomEvent('onSetPageTitle', title);
    }, 0);
};

export const showDialog = (message: string, title: string = 'Bericht van Klaverjassen', actions?: DialogActionShape[] | null,
                           persistent?: boolean, transparent?: boolean, content?: React.ReactElement) => {
    dispatchCustomEvent('onOpenDialog', {message, title, actions, transparent, persistent, content});
};

export const onSeasonEvent = (eventType: EventType, season: Season) => {
    dispatchCustomEvent('onSeasonEvent', {eventType, season});
};

export const onEditSeason = (season: Season | null) => {
    dispatchCustomEvent('onEditSeason', season);
};

export const onEditContestant = (contestant: Contestant | null) => {
    dispatchCustomEvent('onEditContestant', contestant);
};

export const onContestantEvent = (eventType: EventType, contestant: Contestant) => {
    dispatchCustomEvent('onContestantEvent', {eventType, contestant});
};

export const onChangePassword = () => {
    dispatchCustomEvent('onChangePassword', null);
};

export const onOpenUser = (user: User | null) => {
    dispatchCustomEvent('onOpenUser', user);
};

export const onUserEvent = (eventType: EventType, user: User) => {
    dispatchCustomEvent('onUserEvent', {eventType, user});
};