import * as React from 'react';
import {ContestantDetail} from "./contestantDetail";
import {Contestant} from "../../../models/contestantModel";
import {EditType, EventType} from "../../../util/interfaces";
import {addCustomEventListener, removeCustomEventListener} from "../../../util/eventDispatcher";
import {ResponseShape} from "../../../util/requestSender";
import {onContestantEvent} from "../../../util/utils";
import {AppContext} from "../../context/appContext";
import { showDialog } from '@dvrd/dvr-controls';

interface ControllerState {
    contestant: Contestant,
    loading: boolean,
    active: boolean,
}

export class ContestantDetailController extends React.Component<{}, ControllerState> {
    declare context: React.ContextType<typeof AppContext>;
    static contextType = AppContext;

    state = {
        contestant: new Contestant({}),
        loading: false,
        active: false,
    };

    mode: EditType = EditType.NEW;

    onActivate = (contestant: Contestant | null) => {
        this.mode = contestant === null ? EditType.NEW : EditType.EDIT;
        if (contestant === null) contestant = new Contestant({
            first_name: 'Nieuwe deelnemer',
            number: this.getNextNumber()
        });
        this.setState({contestant, active: true});
    };

    onChange = (key: string) => (value: string) => {
        const {contestant} = this.state;
        contestant.setField(key, value);
        this.setState({contestant});
    };

    onSubmit = () => {
        if (this.mode === EditType.EDIT) this.onUpdate();
        else this.onSave();
    };

    onSave = () => {
        const {contestant} = this.state, {season} = this.context.seasonContext,
            seasonId = season === null ? null : season.id;
        if(!seasonId) return;
        this.setState({loading: true});
        contestant.save(seasonId, (contestant: Contestant, success: boolean, response: ResponseShape) => {
            this.setState({loading: false});
            if (success) {
                const season = this.context.seasonContext.season;
                if (season !== null) {
                    season.contestants.push(contestant);
                    this.context.onChangeContext({season});
                }
                onContestantEvent(EventType.CREATE, contestant);
                this.onClose();
                showDialog('De deelnemer is toegevoegd aan het huidige seizoen.', 'Deelnemer toevoegen');
            } else {
                const {message} = response.data, dialogMessage = message === undefined ? 'Het aanmaken van de ' +
                    'deelnemer is niet gelukt, probeer het later opnieuw of neem contact met ons op.' : message;
                showDialog(dialogMessage, 'Aanmaken mislukt');
            }
        });
    };

    onUpdate = () => {
        const {contestant} = this.state;
        this.setState({loading: true});
        contestant.update((contestant: Contestant, success: boolean, response: ResponseShape) => {
            this.setState({loading: false});
            if (success) {
                const {seasonContext} = this.context, season = seasonContext.season;
                if (season !== null) {
                    for (const contest of season.contestants) {
                        if (contest.id === contestant.id) {
                            season.contestants.splice(season.contestants.indexOf(contest), 1, contestant);
                            this.context.onChangeContext({season});
                            break;
                        }
                    }
                }
                onContestantEvent(EventType.UPDATE, contestant);
                this.onClose();
                showDialog('De deelnemer is gewijzigd.', 'Deelnemer wijzigen');
            } else {
                const {message} = response.data, dialogMessage = message === undefined ? 'Het wijzigen van de ' +
                    'deelnemer is niet gelukt, probeer het later opnieuw of neem contact met ons op.' : message;
                showDialog(dialogMessage, 'Wijzigen mislukt');
            }
        });
    };

    onClose = () => {
        this.setState({active: false}, () => {
            this.setState({loading: false, contestant: new Contestant({})});
        });
    };

    getNextNumber = () => {
        const {season} = this.context.seasonContext;
        let number = 0;
        if (season !== null) {
            const occupiedNumbers: number[] = [];
            for (const contestant of season.contestants)
                if (!occupiedNumbers.includes(contestant.number))
                    occupiedNumbers.push(contestant.number);
            // noinspection StatementWithEmptyBodyJS
            while (occupiedNumbers.includes(++number)) ;
        }
        return number;
    };

    componentDidMount = () => {
        addCustomEventListener('ContestantDetailController', 'onEditContestant', this.onActivate);
    };

    componentWillUnmount = () => {
        removeCustomEventListener('ContestantDetailController', 'onEditContestant');
    };

    render = () => {
        const {active, loading, contestant} = this.state;
        if (active)
            return <ContestantDetail onChange={this.onChange} onSubmit={this.onSubmit} onClose={this.onClose}
                                     contestant={contestant} loading={loading}/>;
        return null;
    };
}