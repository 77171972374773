import './style/contestantDetail.scss';

import React, {MouseEventHandler} from 'react';

import {Loader, DvrdInput, DvrdButton, DvrdNumberInput, WithBackground} from '@dvrd/dvr-controls'

import {Contestant} from "../../../models/contestantModel";
import {isNull} from "../../../util/utils";

interface ViewProps {
    onChange: Function,
    onSubmit: MouseEventHandler;
    onClose: any,
    contestant: Contestant,
    loading: boolean,
}

export class ContestantDetail extends React.Component<ViewProps> {
    renderHeader = (): React.ReactNode => {
        const {contestant, onClose} = this.props;
        return (
            <div className='headerContainer'>
                <label className='headerTitle'>{contestant.fullName}</label>
                <span className='common-icon-cross closeButton' onClick={onClose}/>
            </div>
        )
    };

    renderBody = (): React.ReactNode => {
        const {contestant, onChange, loading} = this.props;
        return (
            <div className='bodyContainer'>
                <Loader active={loading}/>
                <DvrdNumberInput onChange={onChange('number')} value={contestant.number.toString()} label='Nummer'/>
                <DvrdInput onChange={onChange('first_name')} value={contestant.firstName} label='Voornaam'/>
                <DvrdInput onChange={onChange('infix')} value={contestant.infix} label='Tussenvoegsel'/>
                <DvrdInput onChange={onChange('last_name')} value={contestant.lastName} label='Achternaam'/>
                <DvrdInput onChange={onChange('email')} value={contestant.email} label='E-mailadres'
                                 type='email'/>
            </div>
        )
    };

    renderFooter = (): React.ReactNode => {
        const {contestant, loading, onClose, onSubmit} = this.props;
        return (
            <div className='footerContainer'>
                <DvrdButton onClick={onClose} label='Annuleren'/>
                <DvrdButton onClick={onSubmit} label='Opslaan'
                                  disabled={loading || isNull(contestant.firstName)}/>
            </div>
        )
    };

    render = () => {
        const {onClose} = this.props;
        return (
            <WithBackground active onClose={onClose}>
                <div className='contestantDetailContainer'>
                    {this.renderHeader()}
                    {this.renderBody()}
                    {this.renderFooter()}
                </div>
            </WithBackground>
        )
    };
}