import * as React from 'react';
import {ScoreDetails} from "./scoreDetails";
import {Season} from "../../../models/seasonModel";
import {ScoreDetailMode} from "../../../util/interfaces";
import {addCustomEventListener, removeCustomEventListener} from "../../../util/eventDispatcher";
import {updateMars, updateScores} from "../../../util/requestSender";
import {Contestant} from "../../../models/contestantModel";
import {AppContext} from "../../context/appContext";
import { showDialog } from '@dvrd/dvr-controls';

interface ControllerState {
    season: Season | null,
    mode: ScoreDetailMode,
    loading: boolean,
}

export class ScoreDetailsController extends React.Component<{}, ControllerState> {
    declare context: React.ContextType<typeof AppContext>;
    static contextType = AppContext;

    state = {
        season: null,
        mode: ScoreDetailMode.SCORES,
        loading: false,
    };

    contestants: Contestant[];
    changedContestants: string[] = [];

    onActivate = (season: Season) => {
        this.setState({season});
        this.contestants = season.contestants;
    };

    onChangeScore = (contestant: Contestant, week: number) => (evt: any) => {
        const value = evt.target.innerHTML;
        for (const contest of this.contestants) {
            if (contest.id === contestant.id) {
                this.updateContestant(contest, week, value);
                return;
            }
        }
    };

    onChangeMode = (mode: ScoreDetailMode) => () => {
        this.setState({mode});
    };

    onSubmit = () => {
        if (this.changedContestants.length === 0) this.onClose();
        const season: Season | null = this.state.season;
        if (season !== null) {
            const seasonId = (season as Season).id;
            this.setState({loading: true});

            let amountUpdated = 0;
            for (const id of this.changedContestants) {
                const contestant = this.findContestant(id);
                if (contestant !== null) {
                    const scores: { [index: number]: number } = {}, marss: { [index: number]: number } = {};
                    for (const score of contestant.scores)
                        scores[score.week] = score.value || 0;
                    for (const mars of contestant.mars)
                        marss[mars.week] = mars.value || 0;
                    updateScores({
                        seasonID: seasonId, contestantID: contestant.id, data: {
                            scores: scores,
                        }, callback: () => {
                            updateMars({
                                seasonID: seasonId, contestantID: contestant.id, data: {
                                    mars: marss,
                                }, callback: () => {
                                    if (++amountUpdated >= this.changedContestants.length) {
                                        this.updateSeason();
                                        this.setState({loading: false});
                                        this.changedContestants = [];
                                        this.onClose();
                                        showDialog('De punten en marsen zijn aangepast.', 'Puntentelling wijzigen');
                                    }
                                }
                            });
                        }
                    });
                }
            }
        }
    };

    onClose = () => {
        if (this.changedContestants.length > 0)
            showDialog('Wil je de wijzigingen opslaan?', 'Wijzigingen opslaan', [
                {label: 'Nee', onClick: this.onConfirmClose}, {label: 'Ja', onClick: this.onSubmit}
            ]);
        else this.onConfirmClose();
    };

    onConfirmClose = () => {
        this.contestants = [];
        this.changedContestants = [];
        this.setState({season: null});
    };

    updateSeason = () => {
        const season = this.state.season, contestants = this.changedContestants;
        if (season !== null) {
            const seasonContestants = (season as Season).contestants;
            for (const contestantId of contestants) {
                const contestant = this.findContestant(contestantId);
                if (contestant !== null)
                    for (let i = 0; i < seasonContestants.length; i++) {
                        if (seasonContestants[i].id === contestant.id) {
                            seasonContestants.splice(i, 1, contestant);
                            break;
                        }
                    }
            }
            (season as Season).contestants = seasonContestants;
            this.context.onChangeContext({season});
        }

    };

    findContestant = (id: string): Contestant | null => {
        for (const contestant of this.contestants)
            if (contestant.id === id) return contestant;
        return null;
    };

    updateContestant = (contestant: Contestant, week: number, value: string) => {
        const {mode} = this.state;
        const scoreName = mode === ScoreDetailMode.SCORES ? 'scores' : 'mars';
        let weekFound = false;
        for (const score of contestant[scoreName])
            if (score.week === week) {
                score.value = parseInt(value);
                weekFound = true;
                break;
            }
        if (!weekFound)
            contestant[scoreName].push({week, value: parseInt(value)});
        const contestants = this.contestants;
        for (let i = 0; i < contestants.length; i++) {
            if (contestants[i].id === contestant.id) {
                contestants.splice(i, 1, contestant);
                if (!this.changedContestants.includes(contestant.id))
                    this.changedContestants.push(contestant.id);
                break;
            }
            this.contestants = contestants;
        }
    };

    componentDidMount = () => {
        addCustomEventListener('ScoreDetailsController', 'onOpenSeasonDetail', this.onActivate);
    };

    componentWillUnmount = () => {
        removeCustomEventListener('ScoreDetailsController', 'onOpenSeasonDetail');
    };

    render = () => {
        const {mode, loading} = this.state, season: Season | null = this.state.season;
        if (season === null) return null;
        return <ScoreDetails onChangeScore={this.onChangeScore} onChangeMode={this.onChangeMode}
                             onSubmit={this.onSubmit} onClose={this.onClose} season={season} mode={mode}
                             loading={loading}/>
    };
}