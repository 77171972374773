import * as React from 'react';
import {Season} from "../../../models/seasonModel";
import {navigate, onSeasonEvent, setPageTitle, updateSeasonScores} from "../../../util/utils";
import {EventType, TablePresentation, TableSorting} from "../../../util/interfaces";
import {SeasonDetail} from "./seasonDetail";
import {addCustomEventListener, removeCustomEventListener} from "../../../util/eventDispatcher";
import {ResponseShape} from "../../../util/requestSender";
import {AppContext} from "../../context/appContext";
import { showDialog } from '@dvrd/dvr-controls';

interface ControllerState {
    season: Season | null,
    loading: boolean,
    presentation: TablePresentation
    order: TableSorting,
    searchValue: string,
    correctWeeks: string,
    correctingWeeks: boolean,
}

export class SeasonDetailController extends React.Component<{}, ControllerState> {
    declare context: React.ContextType<typeof AppContext>;
    static contextType = AppContext;

    state = {
        season: new Season({}),
        loading: false,
        presentation: TablePresentation.MIXED,
        order: TableSorting.NUMBER,
        searchValue: '',
        correctWeeks: '18',
        correctingWeeks: false,
    };

    onSearch = (value: string) => {
        this.setState({searchValue: value});
    };

    onChangePresentation = (presentation: TablePresentation) => {
        let order = this.state.order;
        if (order === TableSorting.MARS || order === TableSorting.SCORES)
            switch (presentation) {
                case TablePresentation.MARS:
                    order = TableSorting.MARS;
                    break;
                case TablePresentation.SCORES:
                    order = TableSorting.SCORES;
                    break;
            }
        this.setState({presentation, order});
    };

    onChangeOrder = (order: TableSorting) => {
        this.setState({order});
    };

    onClickChange = () => {
        const season: Season | null = this.state.season;
        if (season !== null)
            updateSeasonScores(season);
    };

    onChangeWeeks = (value: string) => {
        this.setState({correctWeeks: value});
    };

    onCorrectSeason = () => {
        this.setState({correctingWeeks: true});
    };

    onCloseCorrectSeason = () => {
        this.setState({correctingWeeks: false});
    };

    onSubmitCorrectSeason = () => {
        const {season, correctWeeks} = this.state;
        if (season !== null && correctWeeks.length > 0 && !isNaN(+correctWeeks) && +correctWeeks > 0)
            showDialog('Weet je zeker dat je dit seizoen wilt corrigeren? Hierbij worden overbodige scores van ' +
                'deelnemers definitief verwijderd.', 'Seizoen corrigeren', [{label: 'Nee'}, {
                label: 'Ja',
                onClick: this.onConfirmCorrectSeason
            }]);
    };

    onConfirmCorrectSeason = () => {
        const {season, correctWeeks} = this.state;
        if (season !== null) {
            season.correct((season: Season, success: boolean, response: ResponseShape) => {
                if (success) {
                    onSeasonEvent(EventType.UPDATE, season);
                    showDialog('Het corrigeren van de scores is geslaagd.', 'Seizoen corrigeren');
                    this.setState({correctingWeeks: false});
                } else {
                    const {message} = response.data,
                        dialogMessage = message === undefined ? 'Het corrigeren van de scores is niet gelukt, ' +
                            'probeer het later opnieuw of neem contact met ons op.' : message;
                    showDialog(dialogMessage, 'Seizoen corrigeren mislukt');
                }
            }, +correctWeeks);
        }
    };

    updateSeason = (data: { eventType: EventType, season: Season }) => {
        if (data.eventType === EventType.UPDATE) this.loadSeason(data.season);
    };

    loadSeason = (season?: Season) => {
        const _season = season ?? this.context.seasonContext.season;
        if (!_season)
            navigate('/seizoenen');
        else {
            this.setState({loading: true, season: null}, () => {
                if (_season)
                    Season.get(_season.id, (season: Season) => {
                        this.setState({season, loading: false});
                    });
            });
        }
    };

    componentDidMount = () => {
        this.loadSeason();
        setPageTitle('Puntentelling');
        addCustomEventListener('SeasonDetailController', 'onSeasonEvent', this.updateSeason);
    };

    componentWillUnmount = () => {
        removeCustomEventListener('SeasonDetailController', 'onSeasonEvent');
    };

    render = () => {
        const {presentation, season, order, loading, searchValue, correctWeeks, correctingWeeks} = this.state;
        return <SeasonDetail onChangePresentation={this.onChangePresentation} onChangeOrder={this.onChangeOrder}
                             onSearch={this.onSearch} searchValue={searchValue} season={season} loading={loading}
                             presentation={presentation} order={order} onClickChange={this.onClickChange}
                             correctWeeks={correctWeeks} onChangeWeeks={this.onChangeWeeks}
                             onCorrectSeason={this.onCorrectSeason} correctingWeeks={correctingWeeks}
                             onCloseCorrectWeeks={this.onCloseCorrectSeason}
                             onSubmitCorrectWeeks={this.onSubmitCorrectSeason}/>;
    };
}