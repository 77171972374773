import './style/header.scss';

import React from 'react';
import {navigate} from "../../util/utils";
import {addCustomEventListener, removeCustomEventListener} from "../../util/eventDispatcher";
import {logout} from "../../util/requestSender";
import {UserGroup} from "../../util/interfaces";
import {AppContext} from "../context/appContext";

interface NavigationShape {
    label: string,
    route: string,
    userOnly?: boolean,
    adminOnly?: boolean,
    requiresSeason?: boolean,
}

const navigation: NavigationShape[] = [
    {label: 'Seizoenen', route: '/seizoenen', userOnly: true},
    {label: 'Deelnemers', route: '/deelnemers', userOnly: true, requiresSeason: true},
    {label: 'Puntentelling', route: '/seizoen', requiresSeason: true},
    {label: 'Schema printen', route: '/schema', userOnly: true},
    {label: 'Administrator', route:'/admin', adminOnly: true},
    {label: 'Uitloggen', route: '/logout', userOnly: true},
];

interface HeaderState {
    pageTitle: string,
    visible: boolean,
}

export class Header extends React.Component<{}, HeaderState> {
    declare context: React.ContextType<typeof AppContext>;
    static contextType = AppContext;

    state = {
        pageTitle: 'Klaverjassen',
        visible: false,
    };

    onSetPageTitle = (title: string) => {
        let pageTitle = this.getSeasonLabel();
        this.setState({pageTitle: pageTitle + title});
    };

    onShowHeader = (visible: boolean) => {
        this.setState({visible});
    };

    onNavigate = (route: string) => () => {
        if (route === '/logout') {
            logout({
                callback: () => {
                    this.context.onChangeContext({user: null});
                    navigate('/inloggen');
                }
            });
        } else
            navigate(route);
    };

    getSeasonLabel = (): string => {
        const {season} = this.context.seasonContext;
        if (season !== null) return season.label + ' - ';
        return '';
    };

    userIsAdmin = (): boolean => {
        const {user} = this.context.userContext;
        return user !== null && user.type === UserGroup.ADMIN;
    };

    componentDidMount = () => {
        addCustomEventListener('Header', 'onSetPageTitle', this.onSetPageTitle);
        addCustomEventListener('Header', 'onShowHeader', this.onShowHeader);
    };

    componentWillUnmount = () => {
        removeCustomEventListener('Header', 'onSetPageTitle');
        removeCustomEventListener('Header', 'onShowHeader');
    };

    shouldRenderNavigation = (navigation: NavigationShape) => {
        const userIsAdmin = this.userIsAdmin(), season = this.context.seasonContext.season,
            user = this.context.userContext.user,
            requiresAdmin = navigation.adminOnly === true, requiresUser = navigation.userOnly === true,
            requiresSeason = navigation.requiresSeason === true;

        if (requiresUser && user === null) return false;
        if (requiresAdmin && !userIsAdmin) return false;
        return !(requiresSeason && season === null);
    };

    renderNavigation = (): React.ReactNode => {
        return (
            <div className='navigationsContainer'>
                {navigation.map((nav, key: any) => this.shouldRenderNavigation(nav) ? (
                    <div key={key} className='navigationContainer' onClick={this.onNavigate(nav.route)}>
                        <label className='navigation'>{nav.label}</label>
                    </div>
                ) : null)}
            </div>
        )
    };

    render = () => {
        const {pageTitle, visible} = this.state;
        if (!visible && this.context.userContext.user === null) return null;
        return (
            <div className='applicationHeader'>
                <label className='applicationTitle'>{pageTitle}</label>
                {this.renderNavigation()}
            </div>
        )
    }
}