import {createRequestConfig, sendRequest} from "./requestConfigurator";
import {DELETE, POST, PUT, URLS} from "./constants";
import {setJwt} from "./utils";

interface RequiredCallback {
    callback: Function,
}

interface OptionalCallback {
    callback?: Function,
}

interface RequiredData {
    data: object,
}

interface RequiredID {
    id: string,
}

interface LoginData {
    data: {
        email: string,
        password: string,
    },
}

interface RequiredKey {
    key: string,
}

interface SeasonData {
    data: {
        label: string,
        key?: string,
        status?: string,
        contestants?: string[],
    }
}

interface CorrectData {
    data: {
        weeks?: number,
    }
}

interface SeasonContestantData {
    data: {
        season_id: string,
        contestant_id: string,
    }
}

interface ContestantData {
    data: {
        first_name: string,
        infix?: string,
        last_name?: string,
        email?: string,
        season?: string,
    }
}

interface ScoresData {
    data: {
        scores: { [index: number]: number },
    }
}

interface MarsData {
    data: {
        mars: { [index: number]: number },
    }
}

interface UserData {
    data: {
        email: string,
        type?: string,
        flex?: object,
        activate?: boolean,
    }
}

interface ContestantID {
    contestantID: string,
}

interface SeasonID {
    seasonID: string,
}

export interface ResponseShape {
    data: {
        status: string,
        message?: string,
        [index: string]: any,
    }
}

/*
 ================================================
 Authentication
 ================================================
 */
export const login = (params: RequiredCallback & LoginData) => {
    sendRequest(createRequestConfig({
        url: URLS.POST.LOGIN,
        data: params.data,
        method: POST,
    }), (response: ResponseShape) => {
        const success = responseIsSuccess(response);
        if (success)
            setJwt(response.data.token);
        params.callback(success, response);
    });
};

export const logout = (params: RequiredCallback) => {
    sendRequest(createRequestConfig({
        url: URLS.GET.LOGOUT,
    }), (response: ResponseShape) => {
        setJwt(null);
        params.callback(response);
    });
};

/*
 ================================================
 User
 ================================================
 */
export const getUser = (params: RequiredCallback) => {
    sendRequest(createRequestConfig({
        url: URLS.GET.USER,
    }), params.callback);
};

export const updatePassword = (params: { password: string } & OptionalCallback) => {
    sendRequest(createRequestConfig({
        url: URLS.PUT.PASSWORD,
        method: PUT,
        data: {password: params.password},
    }), params.callback);
};

/*
 ================================================
 User
 ================================================
 */
export const createUser = (params: UserData & OptionalCallback) => {
    sendRequest(createRequestConfig({
        url: URLS.POST.USER,
        data: params.data,
        method: POST,
    }), params.callback);
};

export const getAllUsers = (params: RequiredCallback) => {
    sendRequest(createRequestConfig({
        url: URLS.GET.USERS,
    }), params.callback);
};

export const updateUser = (params: RequiredData & RequiredID & OptionalCallback) => {
    sendRequest(createRequestConfig({
        url: URLS.PUT.USER + params.id,
        data: params.data,
        method: PUT,
    }), params.callback);
};

export const deleteUser = (params: RequiredID & OptionalCallback) => {
    sendRequest(createRequestConfig({
        url: URLS.DELETE.USER + params.id,
        method: DELETE,
    }), params.callback);
};

export const activateUser = (params: RequiredID & OptionalCallback) => {
    sendRequest(createRequestConfig({
        url: URLS.GET.ACTIVATE_USER + params.id,
    }), params.callback);
};
/*
 ================================================
 Season
 ================================================
 */

export const getSeason = (params: RequiredCallback & RequiredID) => {
    sendRequest(createRequestConfig({
        url: URLS.GET.SEASON + params.id,
    }), params.callback);
};

export const getSeasons = (params: RequiredCallback) => {
    sendRequest(createRequestConfig({
        url: URLS.GET.SEASONS,
    }), params.callback);
};

export const getSeasonByKey = (params: RequiredCallback & RequiredKey) => {
    sendRequest(createRequestConfig({
        url: URLS.GET.SEASON_KEY + params.key,
    }), params.callback);
};

export const createSeason = (params: OptionalCallback & SeasonData) => {
    sendRequest(createRequestConfig({
        url: URLS.POST.SEASON,
        data: params.data,
        method: POST,
    }), params.callback);
};

export const addSeasonContestant = (params: SeasonContestantData & OptionalCallback) => {
    sendRequest(createRequestConfig({
        url: URLS.POST.SEASON_CONTESTANT_ADD,
        data: params.data,
        method: POST,
    }), params.callback)
};

export const removeSeasonContestant = (params: SeasonContestantData & OptionalCallback) => {
    sendRequest(createRequestConfig({
        url: URLS.POST.SEASON_CONTESTANT_REMOVE,
        data: params.data,
        method: POST,
    }), params.callback);
};

export const updateSeason = (params: OptionalCallback & RequiredID & RequiredData) => {
    sendRequest(createRequestConfig({
        url: URLS.PUT.SEASON + params.id,
        data: params.data,
        method: PUT,
    }), params.callback);
};

export const deleteSeason = (params: OptionalCallback & RequiredID) => {
    sendRequest(createRequestConfig({
        url: URLS.DELETE.SEASON + params.id,
        method: DELETE,
    }), params.callback);
};

export const correctSeason = (params: RequiredID & CorrectData & OptionalCallback) => {
    sendRequest(createRequestConfig({
        url: URLS.POST.CORRECT_SEASON + params.id,
        data: params.data,
        method: POST,
    }), params.callback);
};

/*
 ================================================
 Contestant
 ================================================
 */

export const createContestant = (params: OptionalCallback & ContestantData) => {
    sendRequest(createRequestConfig({
        url: URLS.POST.CONTESTANT,
        data: params.data,
        method: POST,
    }), params.callback);
};

export const getContestant = (params: RequiredID & RequiredCallback) => {
    sendRequest(createRequestConfig({
        url: URLS.GET.CONTESTANT + params.id,
    }), params.callback)
};

export const getContestants = (params: RequiredCallback) => {
    sendRequest(createRequestConfig({
        url: URLS.GET.CONTESTANTS,
    }), params.callback);
};

export const updateContestant = (params: RequiredData & RequiredID & OptionalCallback) => {
    sendRequest(createRequestConfig({
        url: URLS.PUT.CONTESTANT + params.id,
        data: params.data,
        method: PUT
    }), params.callback);
};

export const deleteContestant = (params: RequiredID & OptionalCallback) => {
    sendRequest(createRequestConfig({
        url: URLS.DELETE.CONTESTANT + params.id,
        method: DELETE,
    }), params.callback);
};

/*
 ================================================
 Scores
 ================================================
 */
export const updateScores = (params: OptionalCallback & ScoresData & SeasonID & ContestantID) => {
    if (Object.keys(params.data.scores).length > 0)
        sendRequest(createRequestConfig({
            url: `${URLS.PUT.SCORES}${params.contestantID}/${params.seasonID}`,
            data: params.data,
            method: PUT,
        }), params.callback);
    else if (params.callback !== undefined && params.callback !== null) params.callback();
};

export const updateMars = (params: OptionalCallback & MarsData & SeasonID & ContestantID) => {
    if (Object.keys(params.data.mars).length > 0)
        sendRequest(createRequestConfig({
            url: `${URLS.PUT.MARS}${params.contestantID}/${params.seasonID}`,
            data: params.data,
            method: PUT,
        }), params.callback);
    else if (params.callback !== undefined && params.callback !== null) params.callback();
};

/*
 ================================================
 Util
 ================================================
 */
export const responseIsSuccess = (response: ResponseShape): boolean => response.data.status === 'success';