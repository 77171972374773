import {
    activateUser,
    createUser,
    deleteUser,
    getAllUsers,
    getUser,
    responseIsSuccess,
    ResponseShape,
    updatePassword,
    updateUser,
} from "../util/requestSender";
import {isNotNull, isNull} from "../util/utils";

export class User extends Object {
    static getAll = (callback: Function) => {
        getAllUsers({
            callback: (response: ResponseShape) => {
                const success = responseIsSuccess(response);
                let users = [];
                if (success) users = response.data.users.map((user: any) => new User(user));
                callback(users, success, response);
            }
        });
    };

    static get = (callback: Function) => {
        getUser({
            callback: (response: ResponseShape) => {
                const success = responseIsSuccess(response);
                let user = null;
                if (success)
                    user = new User(response.data.user);
                callback(user, success, response);
            }
        });
    };
    private readonly props: any = null;

    private fields: { [index: string]: any } = {
        id: '',
        email: '',
        status: '',
        flex: {},
        type: '',
    };

    constructor(props: any) {
        super(props);
        this.props = props;
        this.prepareFields();
    };

    get id(): string {
        return this.fields.id;
    };

    set id(id: string) {
        this.fields.id = id;
    };

    get email(): string {
        return this.fields.email;
    };

    set email(email: string) {
        this.fields.email = email;
    };

    get status(): string {
        return this.fields.status;
    }

    set status(status: string) {
        this.fields.status = status;
    }

    get flex(): { [index: string]: any } {
        return this.fields.flex;
    }

    set flex(flex: { [index: string]: any }) {
        this.fields.flex = flex;
    }

    get type(): string {
        return this.fields.type;
    }

    set type(type: string) {
        this.fields.type = type;
    }

    // noinspection JSUnusedGlobalSymbols
    setFlexField = (name: string, value: any): User => {
        const flex = this.flex;
        flex[name] = value;
        this.flex = flex;
        return this;
    };

    // noinspection JSUnusedGlobalSymbols
    getFlexField = (name: string): any => {
        return this.flex[name];
    };

    setField = (key: string, value: any) => {
        this.fields[key] = value;
    };

    // ADMIN FUNCTIONS
    // noinspection JSUnusedGlobalSymbols
    save = (activate: boolean = false, callback?: Function) => {
        createUser({
            data: {
                email: this.email,
                activate,
                flex: this.flex,
                type: this.type.toString(),
            }, callback: (response: ResponseShape) => {
                const success = responseIsSuccess(response),
                    user = success ? new User(response.data.user) : new User({});
                if (callback !== null && callback !== undefined)
                    callback(user, success, response);
            }
        });
    };

    // noinspection JSUnusedGlobalSymbols
    update = (callback?: Function) => {
        updateUser({
            id: this.id,
            data: {
                email: this.email,
                flex: this.flex,
                type: this.type.toString(),
            },
            callback,
        })
    };

    // noinspection JSUnusedGlobalSymbols
    delete = (callback?: Function) => {
        deleteUser({id: this.id, callback});
    };

    // noinspection JSUnusedGlobalSymbols
    activate = (callback?: Function) => {
        activateUser({
            id: this.id, callback: (response: ResponseShape) => {
                const success = responseIsSuccess(response);
                if (success) this.status = response.data.user.status;
                if (callback !== undefined && callback !== null)
                    callback(this, success, response);
            }
        });
    };

    updatePassword = (password: string, callback?: Function) => {
        updatePassword({password, callback});
    };
    // END ADMIN FUNCTIONS

    protected prepareFields = () => {
        this.initializeField('id');
        this.initializeField('email');
        this.initializeField('status');
        this.initializeField('flex');
        this.initializeField('type');
    };

    private initializeField = (name: string, propName: string = '', acceptNull: boolean = false) => {
        const value = isNull(propName) ? this.props[name] : this.props[propName];
        if (acceptNull || isNotNull(value)) this.fields[name] = value;
    };
}