import {nullify} from '@dvrd/dvr-controls';
import React, {PropsWithChildren, useContext} from 'react';
import {Navigate} from 'react-router-dom';
import {AppContext} from "../context/appContext";

interface Props {
    authenticated?: boolean;
    children: any;
}

export default function AppRoute(props: PropsWithChildren<Props>) {
    if (props.authenticated) {
        const context = useContext(AppContext);
        const {user} = context.userContext;
        if (!nullify(user?.id)) return <Navigate to='/inloggen' replace/>
    }
    return props.children;
}