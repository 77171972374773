import * as React from 'react';
import {Season} from "../../models/seasonModel";
import {SeasonsOverview} from "./seasonsOverview";
import {EventType, SeasonEventShape} from "../../util/interfaces";
import {addCustomEventListener, removeCustomEventListener} from "../../util/eventDispatcher";
import {navigate, onEditSeason, onSeasonEvent, setPageTitle} from "../../util/utils";
import {ResponseShape} from "../../util/requestSender";
import {AppContext} from "../context/appContext";
import { showDialog } from '@dvrd/dvr-controls';

interface ControllerState {
    seasons: Season[],
    loading: boolean,
}

export class SeasonsOverviewController extends React.Component<{}, ControllerState> {
    declare context: React.ContextType<typeof AppContext>;
    static contextType = AppContext;

    state = {
        seasons: new Array<Season>(),
        loading: false,
    };

    onSeasonEvent = (data: SeasonEventShape) => {
        const {eventType, season} = data;
        switch (eventType) {
            case EventType.CREATE:
                this.onCreateEvent(season);
                break;
            case EventType.DELETE:
                this.onDeleteEvent(season);
                break;
            case EventType.UPDATE:
                this.onUpdateEvent(season);
                break;
        }
    };

    onCreateEvent = (season: Season) => {
        const seasons = this.state.seasons.slice();
        seasons.push(season);
        this.setState({seasons});
    };

    onDeleteEvent = (season: Season) => {
        this.setState({seasons: this.state.seasons.filter(seas => seas.id !== season.id)});
    };

    onUpdateEvent = (season: Season) => {
        const seasons = this.state.seasons.slice();
        for (let i = 0; i < seasons.length; i++) {
            if (seasons[i].id === season.id) {
                seasons.splice(i, 1, season);
                this.setState({seasons});
                break;
            }
        }
    };

    onClickSeason = (season: Season) => () => {
        this.context.onChangeContext({season});
        navigate('/seizoen');
    };

    onClickEdit = (season: Season) => (evt: MouseEvent) => {
        evt.stopPropagation();
        onEditSeason(season);
    };

    onClickNew = () => {
        onEditSeason(null);
    };

    onClickDelete = (season: Season) => (evt: MouseEvent) => {
        evt.stopPropagation();
        showDialog(`Weet je zeker dat je seizoen '${season.label}' wilt verwijderen? Hierbij gaan alle scores 
        van dit seizoen verloren`, 'Seizoen verwijderen', [{label: 'Nee'},
            {label: 'Ja', onClick: this.onConfirmDelete(season)}]);
    };

    onConfirmDelete = (season: Season) => () => {
        this.setState({loading: true});
        season.delete((success: boolean, response: ResponseShape) => {
            this.setState({loading: false});
            if (success) onSeasonEvent(EventType.DELETE, season);
            else {
                const {message} = response.data, dialogMessage = message === undefined ? 'Het verwijderen van het ' +
                    'seizoen is niet gelukt, probeer het later opnieuw of neem contact met ons op.' : message;
                showDialog(dialogMessage, 'Verwijderen mislukt');
            }
        });
    };

    loadSeasons = () => {
        this.setState({loading: true});
        Season.getAll((seasons: Season[]) => {
            this.setState({seasons, loading: false});
        });
    };

    getSortedSeasons = (): Season[] => {
        const seasons = this.state.seasons.slice();
        seasons.sort((seasonA, seasonB) => seasonA.label.toLowerCase().localeCompare(seasonB.label.toLowerCase()));
        return seasons;
    };

    componentDidMount = () => {
        setPageTitle('Seizoenen');
        this.loadSeasons();
        addCustomEventListener('SeasonsOverviewController', 'onSeasonEvent', this.onSeasonEvent);
    };

    componentWillUnmount = () => {
        removeCustomEventListener('SeasonsOverviewController', 'onSeasonEvent');
    };

    render = () => {
        const {loading} = this.state;
        return <SeasonsOverview onClickSeason={this.onClickSeason} onClickEdit={this.onClickEdit}
                                onClickNew={this.onClickNew} onClickDelete={this.onClickDelete}
                                seasons={this.getSortedSeasons()} loading={loading}/>
    };
}