import * as React from 'react';
import {Document, Image, Page, Text, View} from '@react-pdf/renderer';
import {ScoreShape, TableSorting} from "../../util/interfaces";
import {Contestant} from "../../models/contestantModel";
import {Season} from "../../models/seasonModel";
import {log} from "../../util/utils";

const bird = require('../../../resource/merel.jpg');
const cards = require('../../../resource/hand_cards.jpg');

export const generateGameSchemes = (schemes: number, contestant: string, contestantNumber: string): React.ReactElement => {
    const styles = {
        page: {
            flexDirection: 'row' as 'row',
            justifyContent: 'space-between' as 'space-between',
            flexWrap: 'wrap' as 'wrap',
            paddingTop: 2,
        },
        tableContainer: {
            width: '50%',
            height: '50%',
            flexDirection: 'row' as 'row',
            justifyContent: 'center' as 'center',
            alignItems: 'center' as 'center',
        },
        table: {
            flexDirection: 'column' as 'column',
            width: '90%',
        },
        imageRow: {
            flexDirection: 'row' as 'row',
        },
        imageRowCell: {
            height: '33%',
        },
        image: {
            width: 75,
        },
        namesContainer: {
            flexDirection: 'column' as 'column',
            justifyContent: 'space-evenly' as 'space-evenly',
            width: '55%',
            marginLeft: '1pt',
        },
        cell: {
            border: '1pt solid black',
            height: '24pt',
        },
        headerCell: {
            border: '1pt solid black',
            height: '26pt',
        },
        pointsTable: {
            // flexDirection: 'row' as 'row',
            flexDirection: 'column' as 'column',
        },
        headCell: {
            fontWeight: 'bold' as 'bold',
        },
        roundCell: {
            width: '20%',
        },
        tableCell: {
            width: '15%',
        },
        scoreCell: {
            width: '40%',
        },
        marsCell: {
            width: '25%',
        },
        row: {
            flexDirection: 'row' as 'row',
        },
        text: {
            marginLeft: 2,
        }
    };

    const schemesPerPage: number = 4, pages: number = schemes / schemesPerPage;

    const renderPages: number[][] = [];
    let schemesRendered = 0;
    for (let i = 0; i < pages; i++) {
        const renderSchemes: number[] = [];
        for (let j = 0; j < schemesPerPage; j++) {
            if (++schemesRendered <= schemes)
                renderSchemes.push(j);
        }
        renderPages.push(renderSchemes);
    }
    const contestantName = contestant.replace(/^\d+ /, '');
    return (
        <Document>
            {renderPages.map((schemes, key: any) => (
                <Page key={key} style={styles.page} orientation='landscape' size='A4'>
                    {schemes.map((scheme, key: any) => (
                        <View key={key} style={styles.tableContainer}>
                            <View style={styles.table}>
                                <View style={styles.imageRow}>
                                    <Image src={bird} style={styles.image}/>
                                    <View style={styles.namesContainer}>
                                        <View style={styles.headerCell}>
                                            <Text style={styles.text}>Deelname No. {contestantNumber}</Text>
                                        </View>
                                        <View style={styles.headerCell}>
                                            <Text style={styles.text}>{contestantName}</Text>
                                        </View>
                                        <View style={styles.headerCell}/>
                                    </View>
                                    <Image src={cards} style={styles.image}/>
                                </View>
                                <View style={styles.pointsTable}>
                                    <View style={styles.row}>
                                        <View style={[styles.cell, styles.roundCell]}/>
                                        <View style={[styles.cell, styles.tableCell]}>
                                            <Text style={[styles.headCell, styles.text]}>Tafel</Text>
                                        </View>
                                        <View style={[styles.cell, styles.scoreCell]}>
                                            <Text style={[styles.headCell, styles.text]}>Punten</Text>
                                        </View>
                                        <View style={[styles.cell, styles.marsCell]}>
                                            <Text style={[styles.headCell, styles.text]}>Marsen</Text>
                                        </View>
                                    </View>
                                    <View style={styles.row}>
                                        <View style={[styles.cell, styles.roundCell]}>
                                            <Text style={styles.text}>Ronde 1</Text>
                                        </View>
                                        <View style={[styles.cell, styles.tableCell]}/>
                                        <View style={[styles.cell, styles.scoreCell]}/>
                                        <View style={[styles.cell, styles.marsCell]}/>
                                    </View>
                                    <View style={styles.row}>
                                        <View style={[styles.cell, styles.roundCell]}>
                                            <Text style={styles.text}>Ronde 2</Text>
                                        </View>
                                        <View style={[styles.cell, styles.tableCell]}/>
                                        <View style={[styles.cell, styles.scoreCell]}/>
                                        <View style={[styles.cell, styles.marsCell]}/>
                                    </View>
                                    <View style={styles.row}>
                                        <View style={[styles.cell, styles.roundCell]}>
                                            <Text style={styles.text}>Ronde 3</Text>
                                        </View>
                                        <View style={[styles.cell, styles.tableCell]}/>
                                        <View style={[styles.cell, styles.scoreCell]}/>
                                        <View style={[styles.cell, styles.marsCell]}/>
                                    </View>
                                    <View style={styles.row}>
                                        <View style={[styles.cell, styles.roundCell]}>
                                            <Text style={styles.text}>Ronde 4</Text>
                                        </View>
                                        <View style={[styles.cell, styles.tableCell]}/>
                                        <View style={[styles.cell, styles.scoreCell]}/>
                                        <View style={[styles.cell, styles.marsCell]}/>
                                    </View>
                                    <View style={styles.row}>
                                        <View style={[styles.cell, styles.roundCell]}>
                                            <Text style={styles.text}>Totaal</Text>
                                        </View>
                                        <View style={[styles.cell, styles.tableCell]}/>
                                        <View style={[styles.cell, styles.scoreCell]}/>
                                        <View style={[styles.cell, styles.marsCell]}/>
                                    </View>
                                </View>
                            </View>
                        </View>
                    ))}
                </Page>
            ))}
        </Document>
    )
};

export const generateTotalsScheme = (contestants: Contestant[], sortedBy: number) => {
    const styles = {
        page: {
            flexDirection: 'row' as 'row',
            justifyContent: 'center' as 'center',
            padding: 40,
        },
        tableContainer: {
            flexDirection: 'column' as 'column',
            alignItems: 'flex-start' as 'flex-start',
            width: '100%',
        },
        row: {
            width: '100%',
            flexDirection: 'row' as 'row',
        },
        cell: {
            flexDirection: 'row' as 'row',
            alignItems: 'center' as 'center',
            borderRight: '1pt solid black',
            borderBottom: '1pt solid black',
            height: 32,
            padding: 4,
        },
        borderLeft: {
            borderLeft: '1pt solid black',
        },
        borderTop: {
            borderTop: '1pt solid black'
        },
        borderBottom: {
            borderBottom: '1pt solid black',
        },
        numberCell: {
            width: '17%',
        },
        contestantCell: {
            width: '53%',
        },
        scoreCell: {
            width: '15%',
        },
        marsCell: {
            width: '15%',
        },
        cellText: {
            fontSize: 14,
        }
    };

    const getSortedContestants = (contestants: Contestant[]): Contestant[] => {
        const copy = contestants.slice();
        copy.sort((contestantA, contestantB) => {
            switch (sortedBy.toString()) {
                case TableSorting.SCORES.toString():
                    return getTotal(contestantB, 'scores') - getTotal(contestantA, 'scores');
                case TableSorting.NUMBER.toString():
                    return contestantA.number - contestantB.number;
                case TableSorting.NAME.toString():
                    return contestantA.fullName.toLowerCase().localeCompare(contestantB.fullName.toLowerCase());
                case TableSorting.MARS.toString():
                    return getTotal(contestantB, 'mars') - getTotal(contestantA, 'mars');
                default:
                    return 0;
            }
        });
        return copy;
    };

    const getTotal = (contestant: Contestant, key: 'mars' | 'scores') => contestant[key].reduce(
        (value: number, score: ScoreShape) => value + score.value, 0);

    const pages: Contestant[][] = [];
    let i = -1;
    const rowsPerPage = 23;
    contestants = getSortedContestants(contestants);
    rootLoop:
        while (true) {
            const page = [];
            for (let j = 0; j < rowsPerPage - 1; j++) {
                if (i++ < contestants.length - 1) {
                    page.push(contestants[i]);
                } else {
                    pages.push(page);
                    break rootLoop;
                }
            }
            pages.push(page);
        }
    return (
        <Document>
            {pages.map((page, key: any) => (
                <Page key={key} size='A4' style={styles.page}>
                    <View style={styles.tableContainer}>
                        <View style={[styles.row, styles.borderBottom]}>
                            <View style={[styles.cell, styles.numberCell, styles.borderLeft, styles.borderTop]}>
                                <Text style={styles.cellText}>Nummer #</Text>
                            </View>
                            <View style={[styles.cell, styles.contestantCell, styles.borderTop]}>
                                <Text style={styles.cellText}>Deelnemer</Text>
                            </View>
                            <View style={[styles.cell, styles.scoreCell, styles.borderTop]}>
                                <Text style={styles.cellText}>Punten</Text>
                            </View>
                            <View style={[styles.cell, styles.marsCell, styles.borderTop]}>
                                <Text style={styles.cellText}>Marsen</Text>
                            </View>
                        </View>
                        {page.map((contestant, key: number) => (
                            <View key={contestant.id}
                                  style={[styles.row, (key + 1) % rowsPerPage === 0 ? styles.borderTop : {}]}
                                  wrap={false}>
                                <View style={[styles.cell, styles.numberCell, styles.borderLeft]}>
                                    <Text style={styles.cellText}>{contestant.paddedNumber}</Text>
                                </View>
                                <View style={[styles.cell, styles.contestantCell]}>
                                    <Text style={styles.cellText}>{contestant.fullName}</Text>
                                </View>
                                <View style={[styles.cell, styles.scoreCell]}>
                                    <Text style={styles.cellText}>{getTotal(contestant, 'scores')}</Text>
                                </View>
                                <View style={[styles.cell, styles.marsCell]}>
                                    <Text style={styles.cellText}>{getTotal(contestant, 'mars')}</Text>
                                </View>
                            </View>
                        ))}
                    </View>
                </Page>
            ))}
        </Document>
    )
};

export const generateRoundScheme = (season: Season) => {
    const styles = {
        page: {
            flexDirection: 'column' as 'column',
            padding: 15,
        },
        table: {
            flexDirection: 'column' as 'column',
            marginBottom: 15,
        },
        noMB: {
            marginBottom: 0,
        },
        row: {
            flexDirection: 'row' as 'row',
        },
        leftBorder: {
            borderLeft: '1pt solid black',
        },
        bottomBorder: {
            borderBottom: '1pt solid black',
        },
        topBorder: {
            borderTop: '2pt solid black',
        },
        cell: {
            borderTop: '1pt solid black',
            borderRight: '1pt solid black',
        },
        cellText: {
            padding: 4,
            paddingTop: 8,
            paddingBottom: 8,
            fontSize: 12,
        },
        headText: {
            paddingTop: 4,
            paddingBottom: 4,
        },
        numberCell: {
            flexDirection: 'row' as 'row',
            justifyContent: 'center' as 'center',
            width: '4%',
            borderLeft: '1pt solid black',
        },
        scoreCell: {
            width: '24%',
        }
    };

    const contestants = season.contestants.slice();
    contestants.sort((contestantA, contestantB) => contestantA.number - contestantB.number);
    const tables: string[][] = [];
    let i = -1;
    rootLoop:
        while (true) {
            const table = [];
            for (let j = 0; j < 4; j++) {
                if (i++ < contestants.length - 1) {
                    const contestant = contestants[i];
                    table.push(`${contestant.paddedNumber}) ${contestant.fullName}`);
                } else {
                    tables.push(table);
                    break rootLoop;
                }
            }
            tables.push(table);
        }
    const columns = [1, 2, 3, 4];
    return (
        <Document>
            <Page size='A4' style={styles.page}>
                {tables.map((table, key: any) => (
                    <View key={key} style={[styles.table, (key + 1) % 4 === 0 ? styles.noMB : {}]} wrap={false}>
                        <View style={styles.row}>
                            <View style={[styles.cell, styles.numberCell]}/>
                            {table.map((name, key: any) => (
                                <View key={key} style={[styles.cell, styles.scoreCell]}>
                                    <Text style={[styles.cellText, styles.headText]}>{name}</Text>
                                </View>
                            ))}
                        </View>
                        {columns.map(col => (
                            <View key={col} style={styles.row}>
                                <View style={[styles.cell, styles.numberCell]}>
                                    <Text style={styles.cellText}>{col}</Text>
                                </View>
                                {columns.map(col => col - 1 < table.length && (
                                    <View key={col} style={[styles.cell, styles.scoreCell]}/>
                                ))}
                            </View>
                        ))}
                        <View style={[styles.row]}>
                            <View style={[styles.cell, styles.numberCell, styles.bottomBorder, styles.topBorder]}>
                                <Text style={styles.cellText}>T</Text>
                            </View>
                            {columns.map(col => col - 1 < table.length && (
                                <View key={col}
                                      style={[styles.cell, styles.scoreCell, styles.bottomBorder, styles.topBorder]}/>
                            ))}
                        </View>
                    </View>
                ))}
            </Page>
        </Document>
    )
};

export const generateAbsenceScheme = (season: Season, firstWeek: number) => {
    const styles = {
        page: {
            flexDirection: 'row' as 'row',
            justifyContent: 'center' as 'center',
            padding: 20,
        },
        tableContainer: {
            flexDirection: 'column' as 'column',
            alignItems: 'flex-start' as 'flex-start',
            width: '100%',
        },
        row: {
            width: '100%',
            flexDirection: 'row' as 'row',
            borderBottom: '1pt solid black',
        },
        cell: {
            flexDirection: 'row' as 'row',
            alignItems: 'center' as 'center',
            borderRight: '1pt solid black',
            height: 32,
            padding: 4,
        },
        borderLeft: {
            borderLeft: '1pt solid black',
        },
        borderTop: {
            borderTop: '1pt solid black'
        },
        borderBottom: {
            borderBottom: '1pt solid black',
        },
        nameCell: {
            width: '30%',
        },
        weekCell: {
            width: '14%',
        },
        headerText: {
            fontWeight: 'bold' as 'bold',
            fontSize: 16,
        },
        cellText: {
            fontSize: 12,
        }
    };

    const contestants = season.contestants;
    contestants.sort((contestantA, contestantB) => contestantA.number - contestantB.number);
    const pages: Contestant[][] = [];
    let i = -1;
    rootLoop:
        while (true) {
            const page = [];
            for (let j = 0; j < 23; j++) {
                if (i++ < contestants.length - 1) {
                    page.push(contestants[i]);
                } else {
                    pages.push(page);
                    break rootLoop;
                }
            }
            pages.push(page);
        }

    const weeks: number[] = [firstWeek];
    let nextWeek = firstWeek;
    for (let i = 1; i < 5; i++) {
        weeks.push(nextWeek === 52 ? 1 : nextWeek + 1);
        nextWeek++;
    }
    log(weeks);

    return (
        <Document>
            {pages.map((page, key: any) => (
                <Page key={key} size='A4' style={styles.page}>
                    <View style={styles.tableContainer}>
                        <View style={[styles.row, styles.borderTop]}>
                            <View style={[styles.cell, styles.nameCell, styles.borderLeft]}>
                                <Text style={styles.cellText}>Deelnemer</Text>
                            </View>
                            {weeks.map(week => (
                                <View key={week} style={[styles.cell, styles.weekCell]}>
                                    <Text style={styles.cellText}>Week {week}</Text>
                                </View>
                            ))}
                        </View>
                        {page.map((contestant, key: any) => (
                            <View key={key} style={[styles.row]}>
                                <View style={[styles.cell, styles.nameCell, styles.borderLeft]}>
                                    <Text
                                        style={styles.cellText}>{contestant.paddedNumber}) {contestant.fullName}</Text>
                                </View>
                                {weeks.map(week => (
                                    <View key={week} style={[styles.cell, styles.weekCell]}/>
                                ))}
                            </View>
                        ))}
                    </View>
                </Page>
            ))}
        </Document>
    )
};