import {isNotNull, isNull} from "../util/utils";
import {ScoreShape} from "../util/interfaces";
import {
    createContestant,
    deleteContestant,
    getContestant,
    getContestants,
    responseIsSuccess,
    ResponseShape,
    updateContestant
} from "../util/requestSender";

interface SeasonsShape {
    season: {
        id: string,
        label: string,
    },
    scores: ScoreShape,
    mars: ScoreShape,
}

interface ContestantShape {
    id: string,
    first_name: string,
    infix?: string,
    last_name?: string,
    email?: string,
    seasons?: SeasonsShape[],
    number: number,
    scores?: ScoreShape[],
    mars?: ScoreShape[],

    [index: string]: any,
}

export class Contestant extends Object {
    static get = (id: string, callback: Function) => {
        getContestant({
            id, callback: (response: ResponseShape) => {
                const success = responseIsSuccess(response);
                let contestant = null;
                if (success)
                    contestant = new Contestant(response.data.contestant);
                callback(contestant, success, response);
            }
        });
    };

    // noinspection JSUnusedGlobalSymbols
    static getAll = (callback: Function) => {
        getContestants({
            callback: (response: ResponseShape) => {
                const success = responseIsSuccess(response), contestants = [];
                if (success)
                    for (const contestant of response.data.contestants)
                        contestants.push(new Contestant(contestant));
                callback(contestants, success, response);
            }
        });
    };

    private fields: ContestantShape = {
        id: '',
        first_name: '',
        infix: undefined,
        last_name: undefined,
        email: undefined,
        seasons: undefined,
        scores: [],
        mars: [],
        number: -1,
    };

    private readonly props: { [index: string]: any } = {};

    constructor(props: object) {
        super(props);
        this.props = props;
        this.initializeFields();
    }

    hasOwnProperty = (name: string): boolean => {
        return isNotNull(this.getField(name));
    };

    getField = (name: string): any => {
        return this.fields[name];
    };

    get id(): string {
        return this.fields.id;
    }

    set id(id: string) {
        this.fields.id = id;
    }

    get firstName(): string {
        return this.fields.first_name;
    }

    get first_name() {
        return this.firstName;
    }

    // noinspection JSUnusedGlobalSymbols
    set firstName(firstName: string) {
        this.fields.first_name = firstName;
    }

    get infix(): string {
        const infix = this.fields.infix;
        if (infix === undefined || infix === null) return '';
        return infix;
    }

    set infix(infix: string) {
        this.fields.infix = infix;
    }

    get lastName(): string {
        const lastName = this.fields.last_name;
        if (lastName === undefined || lastName === null) return '';
        return lastName;
    }

    // noinspection JSUnusedGlobalSymbols
    set lastName(lastName: string) {
        this.fields.last_name = lastName;
    }

    get email(): string {
        const email = this.fields.email;
        if (email === undefined || email === null) return '';
        return email;
    }

    set email(email: string) {
        this.fields.email = email;
    }

    get seasons(): SeasonsShape[] {
        const seasons = this.fields.seasons;
        if (seasons === undefined) return [];
        return seasons;
    }

    set seasons(seasons: SeasonsShape[]) {
        this.fields.seasons = seasons;
    }

    get fullName(): string {
        let name = this.firstName;
        if (isNotNull(this.infix)) name += ` ${this.infix}`;
        if (isNotNull(this.lastName)) name += ` ${this.lastName}`;
        return name;
    };

    get number(): number {
        return this.fields.number;
    }

    set number(number: number) {
        this.fields.number = number;
    }

    get scores(): ScoreShape[] {
        const scores = this.fields.scores;
        return scores === undefined ? [] : scores;
    }

    set scores(scores: ScoreShape[]) {
        this.fields.scores = scores;
    }

    get mars(): ScoreShape[] {
        const mars = this.fields.mars;
        return mars === undefined ? [] : mars;
    }

    set mars(mars: ScoreShape[]) {
        this.fields.mars = mars;
    }

    get validNumber(): string {
        const number = this.number;
        if (number < 0) return '-';
        return number.toString();
    }

    get paddedNumber(): string {
        const number = this.number;
        if(number < 0) return '-';
        return ('0' + number.toString()).slice(-2);
    }

    save = (season?: string, callback?: Function) => {
        const data = {
            first_name: this.firstName,
            infix: this.getNullableField('infix'),
            last_name: this.getNullableField('last_name'),
            email: this.getNullableField('email'),
            number: this.number,
            season,
        };
        createContestant({
            data, callback: (response: ResponseShape) => {
                const success = responseIsSuccess(response);
                if (success)
                    this.id = response.data.contestant.id;
                if (callback !== undefined && callback !== null)
                    callback(this, success, response);
            }
        });
    };

    update = (callback?: Function) => {
        const data = {
            first_name: this.firstName,
            infix: this.infix,
            last_name: this.lastName,
            email: this.email,
            number: this.number,
        };
        updateContestant({
            data, id: this.id, callback: (response: ResponseShape) => {
                if (callback !== undefined && callback !== null)
                    callback(this, responseIsSuccess(response), response);
            }
        });
    };

    delete = (callback?: Function) => {
        deleteContestant({id: this.id, callback});
    };

    setField = (key: string, value: any) => {
        this.fields[key] = value;
    };

    private getNullableField = (name: string) => {
        const value = this.getField(name);
        return isNull(value) ? null : value;
    };

    private initializeFields = () => {
        this.initializeField('id');
        this.initializeField('first_name');
        this.initializeField('infix');
        this.initializeField('last_name');
        this.initializeField('email');
        this.initializeField('number');
        this.initializeSeasons();
        this.initializeScores();
        this.initializeMars();
    };

    private initializeSeasons = () => {
        const seasons = this.props.seasons;
        if (seasons !== null && seasons !== undefined && Array.isArray(seasons)) {
            for (const season of seasons) {
                const seas = this.seasons;
                seas.push(season);
                this.seasons = seas;
            }
        }
    };

    private initializeScores = () => {
        let scores = this.props.scores || {};
        const list = this.scores;
        for (const key of Object.keys(scores)) {
            list.push({week: parseInt(key), value: scores[key]});
        }
        this.scores = list;
    };

    private initializeMars = () => {
        let mars = this.props.mars || {};
        const list = this.mars;
        for (const key of Object.keys(mars)) {
            list.push({week: parseInt(key), value: mars[key]});
        }
        this.mars = list;
    };

    private initializeField = (name: string, propName: string = '', acceptNull: boolean = false) => {
        const value = isNull(propName) ? this.props[name] : this.props[propName];
        if (acceptNull || isNotNull(value)) this.fields[name] = value;
    };
}