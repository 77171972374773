import './style/editSeason.scss';

import React, {MouseEventHandler} from 'react';

import {
    ChangeKeyFunction,
    DvrdButton,
    DvrdSelect,
    Loader,
    SelectItemShape,
    TextField,
    WithBackground
} from '@dvrd/dvr-controls';

import {Season} from "../../../models/seasonModel";
import {EditType, SeasonStatus} from "../../../util/interfaces";
import {hasHover} from "../../../util/utils";

interface ViewProps {
    onSubmit: MouseEventHandler;
    onChange: ChangeKeyFunction;
    onClose: any,
    season: Season,
    seasons: Season[],
    selectedSeason: string,
    loading: boolean,
    mode: EditType,
}

export class EditSeason extends React.Component<ViewProps> {
    dialog: HTMLDivElement | null = null;

    onClose = () => {
        if (this.dialog === null || !hasHover(this.dialog))
            this.props.onClose();
    };

    getStatusItems = (): SelectItemShape[] => [
        {label: 'Privé', value: SeasonStatus.PRIVATE},
        {label: 'Openbaar', value: SeasonStatus.PUBLIC},
    ];

    getSeasonItems = (): SelectItemShape[] => [{label: 'Geen', value: ''}].concat(this.props.seasons.map(season => ({
        label: season.label,
        value: season.id
    })));

    renderHeader = (): React.ReactNode => {
        const {season, onClose} = this.props;
        return (
            <div className='headerContainer'>
                <label className='headerTitle'>{season.label}</label>
                <span className='common-icon-cross closeButton' onClick={onClose}/>
            </div>
        )
    };

    renderBody = (): React.ReactNode => {
        const {season, onChange, selectedSeason, loading, mode} = this.props;
        return (
            <div className='bodyContainer'>
                <Loader active={loading}/>
                <div className='fieldContainer'>
                    <TextField onChange={onChange('label')} value={season.label} label='Naam'/>
                </div>
                <div className='fieldContainer'>
                    <TextField onChange={onChange('key')} value={season.key} label='code'/>
                </div>
                <div className='fieldContainer'>
                    <DvrdSelect value={season.status} items={this.getStatusItems()} onChange={onChange('status')}
                                label='Status' selectOnly optionsContainerHeight='fit-content'/>
                </div>
                {mode === EditType.NEW && <div className='fieldContainer'>
                    <DvrdSelect value={selectedSeason} items={this.getSeasonItems()} optionsContainerHeight='15rem'
                                label='Deelnemers overnemen van seizoen' onChange={onChange('selectedSeason')}/>
                </div>}
            </div>
        )
    };

    renderFooter = (): React.ReactNode => {
        const {onSubmit, loading} = this.props;
        return (
            <div className='footerContainer'>
                <DvrdButton onClick={onSubmit} label='Opslaan' disabled={loading}/>
            </div>
        )
    };

    render = () => {
        return (
            <WithBackground active onClose={this.onClose}>
                <div className='editSeason' ref={ref => {
                    this.dialog = ref
                }}>
                    {this.renderHeader()}
                    {this.renderBody()}
                    {this.renderFooter()}
                </div>
            </WithBackground>
        )
    }
}
