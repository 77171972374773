import Axios from 'axios';

import {debug, getJwt, getSetting, isNotNull, isNull, setJwt} from "./utils";
import {IndexedObject} from "@dvrd/dvr-controls";

Axios.defaults.withCredentials = true;

export interface ConfigInterface {
    url: string,
    method: string,
    data: { [index: string]: any } | null,
    headers: { [index: string]: string },
    errorCallback: Function,

    [index: string]: any,
}

export interface ConfigOptions {
    url: string;
    baseUrl?: string,
    method?: string,
    data?: { [index: string]: any },
    customHeaders?: { [index: string]: string | number | boolean },
    handleError?: Function,

    [index: string]: any,
}

export const sendRequest = (config: ConfigInterface, callback?: Function) => {
    debug('Sending request with config:',config);
    Axios(config).then(response => {
        if(callback !== null && callback != undefined)
            callback(response);
    }, (error) => {
        if(Axios.isCancel(error))
            debug('Request is canceled', error);
        else if(callback !== undefined && callback !== null)
            callback({data: {status: 'error'}});
        else if(!config.errorCallback(error)) throw(error);
    })
};

export const createRequestConfig = (options: ConfigOptions): ConfigInterface => {
    const baseUrl = isNull(options.baseUrl) ? getSetting('platformUrl') : options.baseUrl;
    const url = baseUrl + options.url;
    const remainingConfig: IndexedObject<any> = {...options};
    delete remainingConfig.url;
    const config = setAuthHeader({
        url,
        method: options.method || 'GET',
        data: options.data || null,
        headers: {},
        errorCallback: requestErrorCallback(options),
        withCredentials: true,
        ...remainingConfig,
    });
    debug('Created request config: ', config);
    return config;
};

const setAuthHeader = (config: ConfigInterface): ConfigInterface => {
    const jwtToken = getJwt();
    if (jwtToken !== null) config.headers['Authorization'] = 'JWT ' + jwtToken;
    if (config.hasOwnProperty('customHeaders') && isNotNull(config.customHeaders)) {
        for (const headerKey of Object.keys(config.customHeaders))
            if (!config.headers.hasOwnProperty(headerKey)) // Don't overwrite system needed headers
                config.headers[headerKey] = config.customHeaders[headerKey];
        delete config.customHeaders;
    }
    return config;
};

const requestErrorCallback = (config: ConfigOptions): Function => (error: any): boolean => {
    console.log(error);
    if(error.response && error.response.status === 403)
        setJwt(null);
    if(config.handleError !== null && config.handleError !== undefined)
        return config.handleError(error);
    return false;
};