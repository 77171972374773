import {debug} from "./utils";

interface ListenerShape {
    component: string,
    eventName: string,
    eventHandler: Function,
}

const listeners: ListenerShape[] = [];

export const addCustomEventListener = (componentName: string, eventName: string, eventHandler: Function): boolean => {
    if(listenerExists(componentName, eventName)) return false;
    listeners.push({component: componentName, eventName, eventHandler});
    return true;
};

export const removeCustomEventListener = (componentName: string, eventName: string): boolean => {
    let foundListeners = false;
    for(let i = listeners.length - 1; i >= 0; i--){
        const listener = listeners[i];
        if(listener.component === componentName && listener.eventName === eventName){
            listeners.splice(i, 1);
            foundListeners = true;
        }
    }
    return foundListeners;
};

export const dispatchCustomEvent = (eventName: string, data: any | null) => {
    debug('Dispatching event', {eventName, data});
    for(const listener of listeners){
        if(listener.eventName === eventName)
            listener.eventHandler(data);
    }
};

const listenerExists = (componentName: string, eventName: string) => {
    for(let i = 0; i < listeners.length; i++){
        const listener = listeners[i];
        if(listener.component === componentName && listener.eventName === eventName) return true;
    }
    return false;
};