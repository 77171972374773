import './style/seasonsOverview.scss';

import React, {MouseEventHandler} from 'react';

import {AwesomeIcon, DvrdButton, Loader} from '@dvrd/dvr-controls';

import {Season} from "../../models/seasonModel";
import {SeasonStatus} from "../../util/interfaces";

interface ViewProps {
    onClickSeason: Function,
    onClickEdit: Function,
    onClickNew: MouseEventHandler;
    onClickDelete: Function,
    seasons: Season[],
    loading: boolean,
}

export class SeasonsOverview extends React.Component<ViewProps> {
    getSeasonStatus = (season: Season): string => {
        switch (season.status) {
            case SeasonStatus.PRIVATE:
                return 'Privé';
            case SeasonStatus.PUBLIC:
                return 'Openbaar';
        }
    };

    renderTable = (): React.ReactNode => {
        const {loading} = this.props;
        return (
            <div className='seasonsTable'>
                <Loader active={loading}/>
                {this.renderTableHeader()}
                {this.renderTableBody()}
            </div>
        )
    };

    renderTableHeader = (): React.ReactNode => {
        return (
            <div className='row header'>
                <div className='cell label'>
                    <label className='cellValue'>Naam</label>
                </div>
                <div className='cell key'>
                    <label className='cellValue'>Code</label>
                </div>
                <div className='cell contestants'>
                    <label className='cellValue'>Aantal deelnemers</label>
                </div>
                <div className='cell status'>
                    <label className='cellValue'>Status</label>
                </div>
                <div className='cell action'>
                    <label className='cellValue'>Actie</label>
                </div>
            </div>
        )
    };

    renderTableBody = (): React.ReactNode => {
        const {seasons, onClickEdit, onClickSeason, onClickDelete} = this.props;
        return seasons.map(season => (
            <div key={season.id} className='row' onClick={onClickSeason(season)}>
                <div className='cell label'>
                    <label className='cellValue'>{season.label}</label>
                </div>
                <div className='cell key'>
                    <label className='cellValue'>{season.key}</label>
                </div>
                <div className='cell contestants'>
                    <label className='cellValue'>{season.contestants.length}</label>
                </div>
                <div className='cell status'>
                    <label className='cellValue'>{this.getSeasonStatus(season)}</label>
                </div>
                <div className='cell action'>
                    <div className='cellValue'>
                        <AwesomeIcon name='edit' onClick={onClickEdit(season)} className='editButton'/>
                        <AwesomeIcon name='trash-alt' onClick={onClickDelete(season)} className='deleteButton'/>
                    </div>
                </div>
            </div>
        ))
    };

    render = () => {
        const {onClickNew} = this.props;
        return (
            <div className='seasonsOverview'>
                <label className='title'>Seizoenen</label>
                {this.renderTable()}
                <DvrdButton onClick={onClickNew} label='Nieuw seizoen' ornaments={{element: <span>+</span>}}/>
            </div>
        )
    };
}