export const POST = 'POST', GET = 'GET', PUT = 'PUT', DELETE = 'DELETE';
export const URLS = {
    GET: {
        LOGOUT: '/auth/logout',
        USER: '/user',
        CONTESTANT: '/contestant/',
        CONTESTANTS: '/contestants',
        SEASON: '/season/',
        SEASONS: '/seasons',
        SEASON_KEY: '/season-key/',
        USERS: '/admin/users',
        ACTIVATE_USER: '/admin/user/',
    },
    POST: {
        LOGIN: '/auth/login',
        CONTESTANT: '/contestant',
        SEASON: '/season',
        SEASON_CONTESTANT_ADD: '/season/contestant/add',
        SEASON_CONTESTANT_REMOVE: '/season/contestant/remove',
        CORRECT_SEASON: '/season/correct/',
        USER: '/admin/user',
    },
    PUT: {
        CONTESTANT: '/contestant/',
        SCORES: '/scores/',
        MARS: '/mars/',
        SEASON: '/season/',
        USER: '/admin/user/',
        PASSWORD: '/user/password',
    },
    DELETE: {
        USER: '/admin/user/',
        CONTESTANT: '/contestant/',
        SEASON: '/season/',
    },
};

export const ENTER_CODE = 13, ESCAPE_CODE = 27;