import './style/admin.scss';

import React, {MouseEventHandler} from 'react';
import classNames from 'classnames';

import {AwesomeIcon, DvrdButton, ElementPosition, Loader, PasswordField} from '@dvrd/dvr-controls';

import {User} from "../../models/userModel";

interface ViewProps {
    onClickEdit: Function,
    onClickDelete: Function,
    onClickAdd: MouseEventHandler;
    onClickActivate: Function,
    onChangePassword: Function,
    onSubmitPassword: MouseEventHandler;
    users: User[],
    loading: boolean,
    password: string,
}

export class AdminView extends React.Component<ViewProps> {
    getActivateColor = (user: User): string => {
        switch (user.status) {
            case 'active':
                return 'green';
            case 'inactive':
                return 'red';
            case 'change_password':
                return 'orange';
        }
        return 'red';
    };

    getActivateTitle = (user: User): string => {
        switch (user.status) {
            case 'change_password':
            case 'active':
                return 'Wachtwoord resetten';
            case 'inactive':
                return 'Activeren';
        }
        return 'Activeren';
    };

    renderTable = (): React.ReactNode => {
        return (
            <div className='usersTable'>
                {this.renderHeader()}
                {this.renderBody()}
            </div>
        )
    };

    renderHeader = (): React.ReactNode => {
        return (
            <div className='row header'>
                <div className='cell id'>
                    <label className='cellValue'>ID #</label>
                </div>
                <div className='cell email'>
                    <label className='cellValue'>E-mailadres</label>
                </div>
                <div className='cell type'>
                    <label className='cellValue'>Accounttype</label>
                </div>
                <div className='cell status'>
                    <label className='cellValue'>Status</label>
                </div>
                <div className='cell actions'>
                    <label className='cellValue'>Acties</label>
                </div>
            </div>
        )
    };

    renderBody = (): React.ReactNode => {
        const {users, onClickDelete, onClickActivate, onClickEdit, loading} = this.props;
        return (
            <div className='tableBody'>
                <Loader active={loading}/>
                {users.map(user => (
                    <div key={user.id} className='row'>
                        <div className='cell id'>
                            <label className='cellValue'>{user.id}</label>
                        </div>
                        <div className='cell email'>
                            <label className='cellValue'>{user.email}</label>
                        </div>
                        <div className='cell type'>
                            <label className='cellValue'>{user.type}</label>
                        </div>
                        <div className='cell status'>
                            <label className='cellValue'>{user.status}</label>
                        </div>
                        <div className='cell actions'>
                            <div className='cellValue'>
                                <AwesomeIcon name='edit' onClick={onClickEdit(user)}/>
                                <div title={this.getActivateTitle(user)} style={{display: 'inline-block'}}>
                                    <AwesomeIcon name='power-off' onClick={onClickActivate(user)}
                                                 className={classNames('activateButton', this.getActivateColor(user))}/>
                                </div>
                                <AwesomeIcon name='trash-alt' className='deleteButton' onClick={onClickDelete(user)}/>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    renderFooter = (): React.ReactNode => {
        const {onClickAdd} = this.props;
        return (
            <div className='tableFooter'>
                <DvrdButton onClick={onClickAdd} label='Nieuwe gebruiker'
                            ornaments={{element: <span>+</span>, placement: ElementPosition.LEFT}}/>
            </div>
        )
    };

    render = () => {
        const {onChangePassword, onSubmitPassword, password, loading} = this.props;
        return (
            <div className='adminContainer'>
                <label className='title'>Gebruikers</label>
                <div className='editPasswordContainer'>
                    <PasswordField onChange={onChangePassword} value={password} label='Wachtwoord wijzigen'/>
                    <DvrdButton onClick={onSubmitPassword} label='Opslaan' disabled={loading} className='submitButton'/>
                </div>
                {this.renderTable()}
                {this.renderFooter()}
            </div>
        )
    }
}
