import './style/seasonDetail.scss';

import React, {MouseEventHandler} from 'react';

import {
    DvrdButton,
    DvrdNumberInput,
    ElementPosition,
    DvrdSelect,
    SelectItemShape,
    DvrdInput,
    AwesomeIcon,
    ChangeFunction,
    WithBackground
} from '@dvrd/dvr-controls';

import {Season} from "../../../models/seasonModel";
import {ScoreShape, TablePresentation, TableSorting} from "../../../util/interfaces";
import {stringContains, stringStartsWith} from "../../../util/utils";
import {Contestant} from "../../../models/contestantModel";
import {AppContext} from "../../context/appContext";

interface ViewProps {
    onChangePresentation: ChangeFunction;
    onChangeOrder: ChangeFunction;
    onSearch: ChangeFunction;
    onClickChange: MouseEventHandler;
    onCorrectSeason: MouseEventHandler,
    onChangeWeeks: ChangeFunction;
    onCloseCorrectWeeks: any,
    onSubmitCorrectWeeks: MouseEventHandler;
    correctWeeks: string,
    season: Season | null,
    presentation: TablePresentation,
    order: TableSorting,
    loading: boolean,
    searchValue: string,
    correctingWeeks: boolean,
}

interface ViewState {
    renderPdf: boolean,
}

export class SeasonDetail extends React.Component<ViewProps, ViewState> {
    declare context: React.ContextType<typeof AppContext>;
    static contextType = AppContext;

    timeout: number | null = null;

    state = {
        renderPdf: false,
    };

    onScroll = (container: 'left' | 'right') => (evt: any) => {
        window.requestAnimationFrame(() => {
            const scrollTop = evt.target.scrollTop;
            let elementName = container === 'left' ? 'rightContainer' : 'leftContainer';
            const element = document.querySelector(`div.${elementName}`);
            if (element !== null)
                element.scrollTop = scrollTop;
        });
    };

    getSortItems = (): SelectItemShape[] => {
        const {presentation} = this.props;
        const sortItems: SelectItemShape[] = [
            {label: 'Nummer', value: TableSorting.NUMBER},
            {label: 'Naam', value: TableSorting.NAME},
        ];
        if (presentation !== TablePresentation.SCORES)
            sortItems.push({label: 'Marsen', value: TableSorting.MARS});
        if (presentation !== TablePresentation.MARS)
            sortItems.push({label: 'Score', value: TableSorting.SCORES});
        return sortItems;
    };

    getPresentationItems = (): SelectItemShape[] => [
        {label: 'Scores en marsen', value: TablePresentation.MIXED},
        {label: 'Alleen scores', value: TablePresentation.SCORES},
        {label: 'Alleen marsen', value: TablePresentation.MARS},
    ];

    getMinWeeks = (): number => {
        const {season} = this.props;
        let max = 0;
        if (season !== null)
            for (const contestant of season.contestants)
                max = Math.max(max, contestant.scores.length, contestant.mars.length);
        return Math.max(18, max + 1);
    };

    getContestants = (): Contestant[] => {
        const {season} = this.props;
        if (season === null) return [];
        return this.sortContestants(this.searchContestants(season.contestants));
    };

    searchContestants = (contestants: Contestant[]): Contestant[] => {
        const {searchValue} = this.props;
        if (searchValue.length === 0) return contestants;
        return contestants.filter(contestant => stringStartsWith(contestant.fullName, searchValue)
            || stringContains(contestant.fullName, searchValue) || stringStartsWith(contestant.number.toString(), searchValue))
    };

    sortContestants = (contestants: Contestant[]): Contestant[] => {
        const {order} = this.props;
        const copy = contestants.slice();
        copy.sort((contestA, contestB) => {
            switch (order) {
                case TableSorting.MARS:
                    const marsA = this.getTotalMars(contestA), marsB = this.getTotalMars(contestB);
                    return marsB - marsA;
                case TableSorting.NAME:
                    const nameA = contestA.fullName, nameB = contestB.fullName;
                    return nameA.toLowerCase().localeCompare(nameB.toLowerCase());
                case TableSorting.NUMBER:
                    return contestA.number - contestB.number;
                case TableSorting.SCORES:
                    const scoresA = this.getTotalScore(contestA), scoresB = this.getTotalScore(contestB);
                    return scoresB - scoresA;
            }
        });
        return copy;
    };

    getTotalScore = (contestant: Contestant): number =>
        contestant.scores.reduce((value: number, score: ScoreShape) => value + score.value, 0);

    getTotalMars = (contestant: Contestant): number =>
        contestant.mars.reduce((value: number, mars: ScoreShape) => value + mars.value, 0);

    getScore = (scores: ScoreShape[], weekNumber: number): number | string => {
        for (const data of scores)
            if (data.week === weekNumber) return data.value === 0 ? '-' : data.value;
        return '-';
    };

    getWeekValue = (contestant: Contestant, weekNumber: number): string => {
        const {presentation} = this.props, score = this.getScore(contestant.scores, weekNumber),
            mars = this.getScore(contestant.mars, weekNumber);
        switch (presentation) {
            case TablePresentation.SCORES:
                return score.toString();
            case TablePresentation.MIXED:
                return `${score} / ${mars}`;
            case TablePresentation.MARS:
                return mars.toString();
        }
    };

    addScrollListener = () => {
        const leftElement = document.querySelector('div.leftContainer'),
            rightElement = document.querySelector('div.rightContainer');
        if (leftElement !== null) leftElement.addEventListener('scroll', this.onScroll('left'), {passive: true});
        if (rightElement !== null) rightElement.addEventListener('scroll', this.onScroll('right'), {passive: true});
    };

    removeScrollListener = () => {
        const leftElement = document.querySelector('div.leftContainer'),
            rightElement = document.querySelector('div.rightContainer');
        if (leftElement !== null) leftElement.removeEventListener('scroll', this.onScroll('left'));
        if (rightElement !== null) rightElement.removeEventListener('scroll', this.onScroll('right'));
    };

    renderOptionsBar = (): React.ReactNode => {
        const {
            searchValue,
            onSearch,
            order,
            onChangeOrder,
            presentation,
            onChangePresentation,
            onClickChange,
            onCorrectSeason
        } = this.props;
        const ornament = searchValue.length === 0 ? {
            element: <AwesomeIcon name='magnifying-glass'/>,
            placement: ElementPosition.RIGHT
        } : undefined;
        const {user} = this.context.userContext;
        return (
            <div className='optionsContainer'>
                <DvrdInput onChange={onSearch} value={searchValue} label='Deelnemer zoeken...'
                           ornaments={ornament}/>
                <div className='sortContainer'>
                    <DvrdSelect value={order} items={this.getSortItems()} onChange={onChangeOrder}
                            label='Sorteren' selectOnly optionsContainerHeight='fit-content'/>
                </div>
                <DvrdSelect value={presentation} items={this.getPresentationItems()}
                        onChange={onChangePresentation} label='Weergave' selectOnly
                        optionsContainerHeight='fit-content'/>
                {(user !== null) && <div className='actionContainer'>
                    <DvrdButton onClick={onCorrectSeason} label='Seizoen score'
                            className='correctButton'/>
                    <DvrdButton onClick={onClickChange} label='Scores toevoegen / wijzigen'/>
                </div>}
            </div>
        )
    };

    renderTable = (): React.ReactNode => {
        return (
            <div className='seasonTable'>
                {this.renderHeader()}
                {this.renderBody()}
                {this.renderFooter()}
            </div>
        )
    };

    renderHeader = (): React.ReactNode => {
        return (
            <div className='tableHeader'>
                {this.renderLeftHeader()}
                {this.renderRightHeader()}
            </div>
        )
    };

    /**
     * Render the left (fixed) header containing player number, player mars, player name and total score
     */
    renderLeftHeader = (): React.ReactNode => {
        const {presentation} = this.props;
        return (
            <div className='row header left'>
                <div className='cell name'>
                    <label className='cellValue'>Deelnemer<br/></label>
                </div>
                {presentation !== TablePresentation.MARS && <div className='cell totalScore'>
                    <label className='cellValue'>Totaal punten<br/></label>
                </div>}
                {presentation !== TablePresentation.SCORES && <div className='cell totalMars'>
                    <label className='cellValue'>Totaal marsen<br/></label>
                </div>}
            </div>
        )
    };

    /**
     * Render right (scrollable) header containing te weeks (scores & mars)
     */
    renderRightHeader = (): React.ReactNode => {
        const {presentation} = this.props;
        let label: string;
        switch (presentation) {
            case TablePresentation.MARS:
                label = 'Marsen';
                break;
            case TablePresentation.MIXED:
                label = 'Punten / Marsen';
                break;
            case TablePresentation.SCORES:
                label = 'Punten';
                break;
        }
        const weeks = [];
        for (let i = 0; i < this.getMinWeeks(); i++)
            weeks.push(i);
        return (
            <div className='row header right'>
                {weeks.map(weekNumber => (
                    <div key={weekNumber} className={`cell week ${weekNumber + 1}`}>
                        <label className='cellValue'>{label}<br/>week {weekNumber + 1}</label>
                    </div>
                ))}
            </div>
        )
    };

    renderBody = (): React.ReactNode => {
        return (
            <div className='tableBody'>
                {this.renderLeftBody()}
                {this.renderRightBody()}
            </div>
        )
    };

    renderLeftBody = (): React.ReactNode => {
        const {presentation} = this.props, contestants = this.getContestants();
        return (
            <div className='body left'>
                {contestants.map((contestant) => (
                    <div key={contestant.id} className='row left'>
                        <div className='cell name'>
                            <label className='cellValue'>{`${contestant.paddedNumber} ) ${contestant.fullName}`}</label>
                        </div>
                        {presentation !== TablePresentation.MARS && <div className='cell totalScore'>
                            <label className='cellValue'>{this.getTotalScore(contestant)}</label>
                        </div>}
                        {presentation !== TablePresentation.SCORES && <div className='cell totalMars'>
                            <label className='cellValue'>{this.getTotalMars(contestant)}</label>
                        </div>}
                    </div>
                ))}
            </div>
        );

    };

    renderRightBody = (): React.ReactNode => {
        const contestants = this.getContestants(), weeks: number[] = [];
        for (let i = 0; i < this.getMinWeeks(); i++)
            weeks.push(i);
        return (
            <div className='body'>
                {contestants.map(contestant => (
                    <div key={contestant.id} className='row right'>
                        {weeks.map(weekNumber => (
                            <div key={weekNumber} className={`cell week ${weekNumber + 1}`}>
                                <label className='cellValue'>{this.getWeekValue(contestant, weekNumber + 1)}</label>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        );
    };

    renderFooter = (): React.ReactNode => {
        return null;
    };

    renderCorrectSeason = () => {
        const {correctWeeks, onChangeWeeks, onCloseCorrectWeeks, onSubmitCorrectWeeks, correctingWeeks} = this.props;
        if (correctingWeeks)
            return (
                <WithBackground active onClose={onCloseCorrectWeeks}>
                    <div className='correctWeeksContainer'>
                        <div className='headerContainer'>
                            <label className='headerTitle'>Seizoen score</label>
                            <span className='common-icon-cross closeButton' onClick={onCloseCorrectWeeks}/>
                        </div>
                        <div className='bodyContainer'>
                            <p className='weeksQuestion'>Hoeveel weken moeten worden meegeteld? (standaard 18)</p>
                            <DvrdNumberInput onChange={onChangeWeeks} value={correctWeeks} label='Weken'/>
                        </div>
                        <div className='footerContainer'>
                            <DvrdButton onClick={onSubmitCorrectWeeks} label='Corrigeren'
                                    disabled={correctWeeks.length === 0 || isNaN(+correctWeeks) || +correctWeeks <= 0}/>
                        </div>
                    </div>
                </WithBackground>
            );
        return null;
    };

    componentDidMount = () => {
        this.addScrollListener();
        this.timeout = window.setTimeout(() => {
            this.setState({renderPdf: true})
        }, 3000);
    };

    componentWillUnmount = () => {
        this.removeScrollListener();
        if (this.timeout !== null)
            window.clearTimeout(this.timeout);
    };

    render = () => {
        return (
            <>
                {this.renderCorrectSeason()}
                <div className='seasonDetailsView'>
                    {this.renderOptionsBar()}
                    {this.renderTable()}
                </div>
            </>
        )
    }
}
