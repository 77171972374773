import {Season} from "../models/seasonModel";
import {User} from "../models/userModel";
import {ThemeShape} from "@dvrd/dvr-controls";

export interface ScoreShape {
    week: number,
    value: number,
}

export interface UserContext {
    user: User | null,
}

export interface SeasonContext {
    season: Season | null,
}

export interface ContextShape {
    userContext: UserContext;
    seasonContext: SeasonContext;
    theme: ThemeShape;
    onChangeContext: (config: ChangeContextConfig, callback?: VoidFunction) => void;
}

export type ChangeContextConfig = { user?: User | null; season?: Season | null };

export enum SeasonStatus {
    PUBLIC = 'public',
    PRIVATE = 'private',
}

export enum TablePresentation {
    MIXED, SCORES, MARS
}

export type TableSortingStrings = keyof typeof TableSorting;

export enum TableSorting {
    NUMBER, NAME, SCORES, MARS
}

export enum ScoreDetailMode {
    SCORES, MARS
}

export enum EventType {
    CREATE, UPDATE, DELETE
}

export enum EditType {
    NEW, EDIT,
}

export enum UserGroup {
    ADMIN = 'admin', USER = 'user',
}

export interface SeasonEventShape {
    eventType: EventType,
    season: Season,
}