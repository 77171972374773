import './style/loginView.scss';

import React, {KeyboardEventHandler, MouseEventHandler} from 'react';
import classNames from 'classnames';

import {Button, Loader, PasswordField, TextField} from '@dvrd/dvr-controls';
import {enterPressed} from "../../../util/utils";
import {AppContext} from "../../context/appContext";

interface ViewProps {
    onSubmit: any,
    onChange: Function,
    onSubmitCode: MouseEventHandler & KeyboardEventHandler;
    email: string,
    password: string,
    seasonCode: string,
    loading: boolean,
}

interface ViewState {
    loginOpen: boolean,
}

export class LoginView extends React.Component<ViewProps, ViewState> {
    declare context: React.ContextType<typeof AppContext>;
    static contextType = AppContext;

    state = {
        loginOpen: false,
    };

    onKeyCode = (evt: React.KeyboardEvent) => {
        if (enterPressed(evt))
            this.props.onSubmitCode(evt);
    };

    onKeyLogin = (evt: React.KeyboardEvent) => {
        if (enterPressed(evt))
            this.props.onSubmit();
    };

    onToggleLoginOpen = () => {
        this.setState({loginOpen: !this.state.loginOpen});
    };

    renderSeasonHeader = (): React.ReactNode => {
        return (
            <div className='headerContainer'>
                <label className='headerTitle'>Zoek een seizoen</label>
            </div>
        )
    };

    renderSeasonBody = (): React.ReactNode => {
        const {onChange, seasonCode} = this.props;
        return (
            <div className='bodyContainer'>
                <TextField onChange={onChange('seasonCode')} value={seasonCode} label='Seizoenscode'
                           onKeyDown={this.onKeyCode} autoFocus/>
            </div>
        )
    };

    renderSeasonFooter = (): React.ReactNode => {
        const {onSubmitCode, loading} = this.props;
        return (
            <div className='footerContainer'>
                <Button label='Zoeken' onClick={onSubmitCode} disabled={loading}/>
            </div>
        )
    };


    renderLoginHeader = (): React.ReactNode => {
        return (
            <div className='headerContainer'>
                <label className='headerTitle'>Inloggen</label>
            </div>
        )
    };

    renderLoginBody = (): React.ReactNode => {
        const {onChange, password, email} = this.props;
        return (
            <div className='bodyContainer'>
                <TextField onChange={onChange('email')} value={email} type='email' label='E-mailadres'
                           onKeyDown={this.onKeyLogin}/>
                <PasswordField onChange={onChange('password')} value={password} label='Wachtwoord'
                               onKeyDown={this.onKeyLogin}/>
            </div>
        )
    };

    renderLoginFooter = (): React.ReactNode => {
        const {onSubmit, loading} = this.props;
        return (
            <div className='footerContainer'>
                <Button label='Inloggen' onClick={onSubmit} disabled={loading}/>
            </div>
        )
    };

    render = () => {
        const {loading} = this.props, {loginOpen} = this.state;
        return (
            <div className='loginContainer'>
                <Loader active={loading}/>
                <div className='seasonCodeView'>
                    {this.renderSeasonHeader()}
                    {this.renderSeasonBody()}
                    {this.renderSeasonFooter()}
                </div>
                {this.context.userContext.user === null &&
                <>
                    <div className='separatorContainer'>
                        <div className='separator'/>
                        <span className='separatorText' onClick={this.onToggleLoginOpen}>Inloggen</span>
                    </div>
                    <div className={classNames('loginView', loginOpen && 'open')}>
                        <div className='loginContent'>
                            {this.renderLoginHeader()}
                            {this.renderLoginBody()}
                            {this.renderLoginFooter()}
                        </div>
                    </div>
                </>}
            </div>
        )
    };
}