import * as React from 'react';
import {User} from "../../models/userModel";
import {AdminView} from "./adminView";
import {onOpenUser, onUserEvent, setPageTitle} from "../../util/utils";
import {responseIsSuccess, ResponseShape} from "../../util/requestSender";
import {EventType} from "../../util/interfaces";
import {addCustomEventListener, removeCustomEventListener} from "../../util/eventDispatcher";
import {AppContext} from "../context/appContext";
import { showDialog } from '@dvrd/dvr-controls';

interface State {
    users: User[],
    loading: boolean,
    password: string,
}

export class AdminController extends React.Component<{}, State> {
    declare context: React.ContextType<typeof AppContext>;
    static contextType = AppContext;

    state = {
        users: new Array<User>(),
        loading: true,
        password: '',
    };

    onUserEvent = (data: { eventType: EventType, user: User }) => {
        const {eventType, user} = data;
        let users = this.state.users.slice();
        switch (eventType) {
            case EventType.CREATE:
                users.push(user);
                break;
            case EventType.DELETE:
                users = users.filter(usr => usr.id !== user.id);
                break;
            case EventType.UPDATE:
                for (const usr of users) {
                    if (usr.id === user.id) {
                        users.splice(users.indexOf(usr), 1, user);
                        break;
                    }
                }
                break;
        }
        this.setState({users});
    };

    onChangePassword = (value: string) => {
        this.setState({password: value});
    };

    onSubmitPassword = () => {
        const {password} = this.state, user = this.context.userContext.user;
        if (user !== null) {
            this.setState({loading: true});
            user.updatePassword(password, (response: ResponseShape) => {
                this.setState({loading: false});
                if (responseIsSuccess(response)) {
                    showDialog('Je wachtwoord is gewijzigd.', 'Wachtwoord wijzigen');
                    this.setState({password: ''});
                } else {
                    showDialog(response.data.message || 'Het wijzigen van je wachtwoord is niet gelukt', 'Wachtwoord wijzigen');
                }
            });
        }
    };

    onClickEdit = (user: User) => () => {
        onOpenUser(user);
    };

    onClickDelete = (user: User) => () => {
        showDialog(`Weet je zeker dat je gebruiker '${user.email}' wilt verwijderen?`, 'Gebruiker verwijderen', [{label: 'Nee'}, {
            label: 'Ja',
            onClick: this.onConfirmDelete(user)
        }]);
    };

    onConfirmDelete = (user: User) => () => {
        this.setState({loading: true});
        user.delete((response: ResponseShape) => {
            this.setState({loading: false});
            if (responseIsSuccess(response)) {
                onUserEvent(EventType.DELETE, user);
                showDialog('De gebruiker is verwijderd', 'Gebruiker verwijderen');
            } else showDialog(response.data.message || 'Het verwijderen is niet gelukt', 'Gebruiker verwijderen');
        })
    };

    onClickAdd = () => {
        onOpenUser(null);
    };

    onClickActivate = (user: User) => () => {
        this.setState({loading: true});
        user.activate((user: User, success: boolean, response: ResponseShape) => {
            this.setState({loading: false});
            if (success) {
                onUserEvent(EventType.UPDATE, user);
                showDialog('De gebruiker is geactiveerd.', 'Gebruiker activeren');
            } else showDialog(response.data.message || 'Het activeren is niet gelukt', 'Gebruiker activeren');
        });
    };

    loadUsers = () => {
        User.getAll((users: User[]) => {
            const contextUser = this.context.userContext.user;
            users = users.filter(user => contextUser === null || user.id !== contextUser.id);
            this.setState({users, loading: false});
        });
    };

    componentDidMount = () => {
        setPageTitle('Administratie');
        this.loadUsers();
        addCustomEventListener('AdminController', 'onUserEvent', this.onUserEvent);
    };

    componentWillUnmount = () => {
        removeCustomEventListener('AdminController', 'onUserEvent');
    };

    render = () => {
        const {users, loading, password} = this.state;
        return <AdminView onClickEdit={this.onClickEdit} onClickDelete={this.onClickDelete} onClickAdd={this.onClickAdd}
                          onClickActivate={this.onClickActivate} users={users} loading={loading}
                          onChangePassword={this.onChangePassword} onSubmitPassword={this.onSubmitPassword}
                          password={password}/>
    }
}