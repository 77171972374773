import './style/userDetail.scss';

import React, {MouseEventHandler} from 'react';

import {DvrdButton, Checkbox, Loader, DvrdSelect, SelectItemShape, DvrdInput, WithBackground} from '@dvrd/dvr-controls';

import {User} from "../../../models/userModel";
import {EditType, UserGroup} from "../../../util/interfaces";
import {hasHover} from "../../../util/utils";

interface Props {
    onChange: Function
    onSubmit: MouseEventHandler;
    onClose: any,
    loading: boolean,
    activate: boolean,
    mode: EditType,
    user: User
}

export class UserDetail extends React.Component<Props> {
    dialog: HTMLDivElement | null = null;

    onClose = () => {
        const {onClose} = this.props;
        if (this.dialog === null || !hasHover(this.dialog))
            onClose();
    };

    getTypeItems = (): SelectItemShape[] => [
        {label: 'Gebruiker', value: UserGroup.USER},
        {label: 'Administrator', value: UserGroup.ADMIN},
    ];

    renderHeader = (): React.ReactNode => {
        const {user, onClose} = this.props;
        return (
            <div className='headerContainer'>
                <label className='headerTitle'>{user.email}</label>
                <span className='common-icon-cross closeButton' onClick={onClose}/>
            </div>
        )
    };

    renderBody = (): React.ReactNode => {
        const {user, onChange, loading, activate, mode} = this.props;
        return (
            <div className='bodyContainer'>
                <Loader active={loading}/>
                <DvrdInput onChange={onChange('email')} value={user.email} label='E-mailadres' type='email' autoFocus/>
                <DvrdSelect value={user.type} items={this.getTypeItems()} onChange={onChange('type')} label='Accounttype'
                        selectOnly optionsContainerHeight='fit-content' className='typeSelect'/>
                {mode === EditType.NEW &&
                <Checkbox checked={activate} onCheck={onChange('activate')} label='Activeren'/>}
            </div>
        )
    };

    renderFooter = (): React.ReactNode => {
        const {onSubmit, onClose, loading} = this.props;
        return (
            <div className='footerContainer'>
                <DvrdButton onClick={onClose} label='Sluiten' disabled={loading}/>
                <DvrdButton onClick={onSubmit} label='Opslaan' disabled={loading}/>
            </div>
        )
    };

    render = () => {
        return (
            <WithBackground active onClose={this.onClose}>
                <div className='userDetailContainer' ref={ref => {
                    this.dialog = ref
                }}>
                    {this.renderHeader()}
                    {this.renderBody()}
                    {this.renderFooter()}
                </div>
            </WithBackground>
        )
    };
}