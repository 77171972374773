import * as Sentry from '@sentry/browser';
import './settings';

import {debug, log} from "./utils";
import {dispatchCustomEvent} from "./eventDispatcher";
import { IndexedObject } from '@dvrd/dvr-controls';

declare global {
    // noinspection JSUnusedGlobalSymbols
    interface Window {
        log: Function;
        settings: IndexedObject<any>;
        modeSettings: { [index: string]: any };
        dce: Function;
        mode: 'prod' | 'accept' | 'dev';
    }
}

if (window.settings) {
    const settings = window.settings;
    const mode = settings.mode;

    if (mode === 'prod')
        Sentry.init({dsn: "https://cc2e1d75c94345a0afceb5c904217eae@sentry.io/1515908"});
    debug('Started application with settings', window.settings);
} else
    throw new Error('Missing global settings object');

window.log = log;
window.dce = window.settings.mode === 'dev' ? (name: string, data: any) => {
    dispatchCustomEvent(name, data);
} : () => {
};