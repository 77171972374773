import './style/generateSchemes.scss';

import React from 'react';
import {Scheme} from "./generateSchemesController";

interface ViewProps {
    documents: Scheme[],
}

export class GenerateSchemes extends React.Component<ViewProps> {
    render = () => {
        const {documents} = this.props;
        return (
            <div className='generateSchemes'>
                <p className='desc'>Kies welk schema je wilt maken:</p>
                <div className='schemesContainer'>
                    {documents.map((doc, key: any) => (
                        <div key={key} className='schemeContainer' onClick={doc.onClick}>
                            <div className='hoverContainer'/>
                            <label className='schemeLabel'>{doc.label}</label>
                            <div className='imageContainer' style={{backgroundImage: `url(${doc.img})`}}/>
                        </div>
                    ))}
                </div>
            </div>
        )
    };
}
