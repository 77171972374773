import * as React from 'react';
import {LoginView} from "./loginView";
import {navigate, onChangePassword} from "../../../util/utils";
import {Season} from "../../../models/seasonModel";
import {login, ResponseShape} from "../../../util/requestSender";
import {User} from "../../../models/userModel";
import {AppContext} from "../../context/appContext";
import { showDialog } from '@dvrd/dvr-controls';

interface LoginState {
    email: string,
    password: string,
    seasonCode: string,
    loading: boolean,

    [key: string]: string | boolean,
}

export class LoginController extends React.Component<{}, LoginState> {
    declare context: React.ContextType<typeof AppContext>;
    static contextType = AppContext;

    state = {
        email: '',
        password: '',
        seasonCode: '',
        loading: false,
    };

    onChange = (name: string) => (value: string) => {
        this.setState({[name]: value});
    };

    onSubmit = () => {
        this.setState({loading: true});
        const {email, password, seasonCode} = this.state;
        login({
            data: {email, password}, callback: (success: boolean, response: ResponseShape) => {
                this.setState({loading: false});
                if (success) {
                    const user = new User(response.data.user);
                    this.context.onChangeContext({user});
                    this.setState({email: '', password: ''});
                    if (seasonCode.length > 0)
                        this.onSubmitCode();
                    if (user.status === 'change_password')
                        onChangePassword();
                } else {
                    let {message} = response.data;
                    message = message === undefined ? 'Het inloggen is niet gelukt' : message;
                    showDialog(message, 'Inloggen mislukt');
                }
            }
        });
    };

    onSubmitCode = () => {
        const {seasonCode} = this.state;
        this.setState({loading: true});
        Season.getByKey(seasonCode, (season: Season | null, success: boolean, response: ResponseShape) => {
            this.setState({loading: false});
            if (success && season !== null) {
                this.context.onChangeContext({season});
                navigate('/seizoen');
            } else {
                const message = response.data.message === undefined ? 'Seizoen niet gevonden' : response.data.message;
                showDialog(message, 'Seizoen zoeken');
            }
        });
    };

    processSeasonParam = () => {
        const params = new URLSearchParams(location.search);
        if (params.has('seizoencode')) {
            this.setState({seasonCode: params.get('seizoencode')!}, () => {
                window.history.replaceState({}, document.title, '/inloggen');
                if (params.get('preventSubmit') !== '1')
                    this.onSubmitCode();
            });
        }
    };

    componentDidMount = () => {
        this.processSeasonParam();
    };

    render = () => {
        const {email, password, loading, seasonCode} = this.state;
        return <LoginView onSubmit={this.onSubmit} onChange={this.onChange} onSubmitCode={this.onSubmitCode}
                          email={email} password={password} seasonCode={seasonCode} loading={loading}/>
    };
}