import * as React from 'react';
import {EventType} from "../../util/interfaces";
import {Contestant} from "../../models/contestantModel";
import {ContestantsOverview} from "./contestantsOverview";
import {navigate, onContestantEvent, onEditContestant, setPageTitle} from "../../util/utils";
import {Season} from "../../models/seasonModel";
import {addSeasonContestant, removeSeasonContestant, responseIsSuccess, ResponseShape} from "../../util/requestSender";
import {AppContext} from "../context/appContext";
import { showDialog } from '@dvrd/dvr-controls';

interface ControllerState {
    seasons: Season[],
    selectedSeason: string,
    selectedContestant: string,
    loading: boolean,
    selectingFromSeason: boolean,
    loadingSeasons: boolean,
}

export class ContestantsOverviewController extends React.Component<{}, ControllerState> {
    declare context: React.ContextType<typeof AppContext>;
    static contextType = AppContext;

    state = {
        seasons: new Array<Season>(),
        selectedSeason: '',
        selectedContestant: '',
        loading: false,
        selectingFromSeason: false,
        loadingSeasons: false,
    };

    onClickContestant = (contestant: Contestant) => () => {
        this.setState({loading: true});
        Contestant.get(contestant.id, (contestant: Contestant) => {
            this.setState({loading: false});
            onEditContestant(contestant);
        })
    };

    onClickDelete = (contestant: Contestant) => (evt: MouseEvent) => {
        evt.stopPropagation();
        showDialog(`Weet je zeker dat je deelnemer '${contestant.fullName}' wilt verwijderen uit dit seizoen? 
        Als deze deelnemer alleen aan dit seizoen deelneemt, wordt de deelnemer definitief verwijderd.`, '' +
            'Deelnemer verwijderen', [{label: 'Nee'}, {label: 'Ja', onClick: this.onConfirmDelete(contestant)}]);
    };

    onConfirmDelete = (contestant: Contestant) => () => {
        const {season} = this.context.seasonContext;
        if (season !== null) {
            this.setState({loading: true});
            removeSeasonContestant({
                data: {season_id: season.id, contestant_id: contestant.id}, callback: (response: ResponseShape) => {
                    if (responseIsSuccess(response)) {
                        const contestant = new Contestant(response.data.contestant),
                            season = this.context.seasonContext.season;
                        if (season !== null) {
                            season.contestants = season.contestants.filter((contest: Contestant) => contest.id !== contestant.id);
                            this.context.onChangeContext({season});
                        }
                        onContestantEvent(EventType.DELETE, contestant);
                        if (contestant.seasons.length === 0) this.onDeleteContestant(contestant);
                        else {
                            this.setState({loading: false});
                            showDialog('De deelnemer is uit dit seizoen verwijderd.', 'Deelnemer verwijderen');
                        }
                    }
                }
            });
        }
    };

    onDeleteContestant = (contestant: Contestant) => {
        contestant.delete((success: boolean, response: ResponseShape) => {
            this.setState({loading: false});
            if (!success) {
                const {message} = response.data,
                    dialogMessage = message === undefined ? 'Het verwijderen van de deelnemer is niet gelukt, probeer ' +
                        'het later opnieuw of neem contact met ons op. De deelnemer is wel verwijderd uit het huidige' +
                        ' seizoen.' : message;
                showDialog(dialogMessage, 'Verwijderen mislukt');
            }
        });
    };

    onClickAdd = () => {
        onEditContestant(null);
    };

    onClickAddFromSeason = () => {
        this.setState({selectingFromSeason: true}, () => {
            const {seasons} = this.state;
            if (seasons.length === 0) {
                this.setState({loadingSeasons: true});
                Season.getAll((seasons: Season[]) => {
                    const contextSeason = this.context.seasonContext.season;
                    seasons = seasons.filter(season => contextSeason === null || contextSeason.id !== season.id);
                    this.setState({seasons, loadingSeasons: false});
                });
            }
        });
    };

    onSelectSeason = (value: string) => {
        this.setState({selectedSeason: value, selectedContestant: ''});
    };

    onSelectSeasonContestant = (value: string) => {
        this.setState({selectedContestant: value});
    };

    onSubmitContestant = () => {
        const {selectedContestant} = this.state, {season} = this.context.seasonContext;
        if (season !== null) {
            addSeasonContestant({
                data: {contestant_id: selectedContestant, season_id: season.id},
                callback: (response: ResponseShape) => {
                    this.onCloseSelectFromSeason();
                    if (responseIsSuccess(response)) {
                        const {contestant} = response.data;
                        onContestantEvent(EventType.CREATE, new Contestant(contestant));
                    } else {
                        const {message} = response.data, dialogMessage = message === undefined ? 'Het is niet gelukt ' +
                            'om de deelnemer toe te voegen aan dit seizoen, probeer het later opnieuw of neem contact ' +
                            'met ons op.' : message;
                        showDialog(dialogMessage, 'Toevoegen mislukt');
                    }
                }
            });
        }
    };

    onCloseSelectFromSeason = () => {
        this.setState({selectingFromSeason: false, selectedSeason: '', selectedContestant: ''});
    };

    getSortedContestants = (): Contestant[] => {
        const {season} = this.context.seasonContext;
        const contestants: Contestant[] = season === null ? [] : season.contestants.slice();
        contestants.sort((contestantA, contestantB) => contestantA.number - contestantB.number);
        return contestants;
    };

    componentDidMount = () => {
        if (this.context.seasonContext.season === null) navigate('/seizoenen');
        else setPageTitle('Deelnemers');
    };

    render = () => {
        const {seasons, selectedSeason, selectedContestant, loading, selectingFromSeason, loadingSeasons} = this.state;
        return <ContestantsOverview onClickContestant={this.onClickContestant} onClickDelete={this.onClickDelete}
                                    onClickAdd={this.onClickAdd} onClickAddFromSeason={this.onClickAddFromSeason}
                                    onSelectSeason={this.onSelectSeason}
                                    onSelectSeasonContestant={this.onSelectSeasonContestant}
                                    onSubmitContestant={this.onSubmitContestant}
                                    onCloseSelectFromSeason={this.onCloseSelectFromSeason}
                                    contestants={this.getSortedContestants()}
                                    seasons={seasons} selectedSeason={selectedSeason}
                                    selectedContestant={selectedContestant} loading={loading}
                                    selectingFromSeason={selectingFromSeason} loadingSeasons={loadingSeasons}/>
    };
}