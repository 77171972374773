import './style/changePassword.scss';

import * as React from 'react';

import {PasswordField, Button, Loader, WithBackground, showDialog} from '@dvrd/dvr-controls';

import {responseIsSuccess, ResponseShape} from "../../util/requestSender";
import {addCustomEventListener, removeCustomEventListener} from "../../util/eventDispatcher";
import {AppContext} from "../context/appContext";

interface State {
    active: boolean,
    password: string,
    loading: boolean,
}

export class ChangePassword extends React.Component<{}, State> {
    declare context: React.ContextType<typeof AppContext>;
    static contextType = AppContext;
    state = {
        active: false,
        password: '',
        loading: false,
    };

    onOpen = () => {
        this.setState({active: true});
    };

    onClose = () => {
        this.setState({active: false, loading: false}, () => {
            this.setState({password: ''});
        });
    };

    onChange = (value: string) => {
        this.setState({password: value});
    };

    onSubmit = () => {
        const user = this.context.userContext.user, {password} = this.state;
        if (user !== null) {
            this.setState({loading: true});
            user.updatePassword(password, (response: ResponseShape) => {
                this.setState({loading: false});
                if (responseIsSuccess(response)) {
                    this.onClose();
                    showDialog('Je wachtwoord is aangepast.', 'Wachtwoord wijzigen');
                } else {
                    const {message} = response.data, dialogMessage = message === undefined ? 'Het wijzigen van je ' +
                        'wachtwoord is niet gelukt, probeer het later opnieuw of neem contact met ons op.' : message;
                    showDialog(dialogMessage, 'Wachtwoord wijzigen');
                }
            });
        }
    };

    componentDidMount = () => {
        addCustomEventListener('ChangePassword', 'onChangePassword', this.onOpen);
    };

    componentWillUnmount = () => {
        removeCustomEventListener('ChangePassword', 'onChangePassword');
    };

    render = () => {
        const {password, loading, active} = this.state;
        if (active)
            return <WithBackground active onClose={this.onClose} preventBackgroundClick escapeDisabled>
                <div className='changePasswordContainer'>
                    <Loader active={loading}/>
                    <label className='desc'>Geef een nieuw wachtwoord op voor je account</label>
                    <PasswordField onChange={this.onChange} value={password} label='Wachtwoord'
                                   containerClass='passwordField'/>
                    <Button onClick={this.onSubmit} label='Opslaan'/>
                </div>
            </WithBackground>
        return null;
    };
}