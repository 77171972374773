import {isNotNull, isNull} from "../util/utils";
import {SeasonStatus} from "../util/interfaces";
import {
    correctSeason,
    createSeason,
    deleteSeason,
    getSeason,
    getSeasonByKey,
    getSeasons,
    responseIsSuccess,
    ResponseShape,
    updateSeason
} from "../util/requestSender";
import {Contestant} from "./contestantModel";

interface SeasonShape {
    id: string,
    label: string,
    status: SeasonStatus,
    key?: string,
    contestants?: Contestant[],

    [index: string]: string | any[] | undefined | SeasonStatus,
}

export class Season extends Object {
    static get = (id: string, callback: Function) => {
        getSeason({
            id, callback: (response: ResponseShape) => {
                const success = responseIsSuccess(response);
                let season = null;
                if (success)
                    season = new Season(response.data.season);
                callback(season, success, response);
            }
        });
    };

    static getByKey = (key: string, callback: Function) => {
        getSeasonByKey({
            key, callback: (response: ResponseShape) => {
                const success = responseIsSuccess(response);
                let season = null;
                if (success)
                    season = new Season(response.data.season);
                callback(season, success, response);
            }
        });
    };

    static getAll = (callback: Function) => {
        getSeasons({
            callback: (response: ResponseShape) => {
                const success = responseIsSuccess(response), seasons = [];
                if (success)
                    for (const season of response.data.seasons)
                        seasons.push(new Season(season));
                callback(seasons, success, response);
            }
        });
    };

    private fields: SeasonShape = {
        id: '',
        label: '',
        status: SeasonStatus.PRIVATE,
        key: undefined,
        contestants: undefined,
    };

    private readonly props: { [index: string]: any } = {};

    constructor(props: object) {
        super(props);
        this.props = props;
        this.initializeFields();
    }

    hasOwnProperty = (name: string): boolean => {
        return isNotNull(this.getField(name));
    };

    getField = (name: string): any => {
        return this.fields[name];
    };

    get id(): string {
        return this.fields.id;
    }

    set id(id: string) {
        this.fields.id = id;
    }

    get label(): string {
        return this.fields.label;
    }

    set label(label: string) {
        this.fields.label = label;
    }

    get status(): SeasonStatus {
        return this.fields.status;
    }

    set status(status: SeasonStatus) {
        this.fields.status = status;
    }

    get key(): string {
        const key = this.fields.key;
        if (key === undefined || key === null) return '';
        return key;
    }

    set key(key: string) {
        this.fields.key = key;
    }

    get contestants(): Contestant[] {
        const contestants = this.fields.contestants;
        if (contestants === undefined) return [];
        return contestants;
    }

    set contestants(contestants: Contestant[]) {
        this.fields.contestants = contestants;
    }

    // noinspection JSUnusedGlobalSymbols
    addContestant = (contestant: Contestant) => {
        const contestants = this.contestants;
        contestants.push(contestant);
        this.contestants = contestants;
    };

    // noinspection JSUnusedGlobalSymbols
    removeContestants = (id: string) => {
        this.contestants = this.contestants.filter((contestant: Contestant) => contestant.id !== id);
    };

    save = (contestants?: string[], callback?: Function) => {
        const data = {
            label: this.label,
            key: this.key,
            status: this.status,
            contestants,
        };
        createSeason({
            data, callback: (response: ResponseShape) => {
                const success = responseIsSuccess(response);
                if (success) {
                    this.id = response.data.season.id;
                    this.initializeContestants(response.data.season.contestants);
                }
                if (callback !== undefined && callback !== null)
                    callback(this, success, response);
            }
        });
    };

    update = (callback?: Function) => {
        const data = {
            label: this.label,
            key: this.key,
            status: this.status,
        };
        updateSeason({
            id: this.id, data, callback: (response: ResponseShape) => {
                if (callback !== undefined && callback !== null) {
                    callback(this, responseIsSuccess(response), response);
                }
            }
        });
    };

    delete = (callback?: Function) => {
        deleteSeason({
            id: this.id, callback: (response: ResponseShape) => {
                if (callback !== undefined && callback !== null)
                    callback(responseIsSuccess(response), response);
            }
        });
    };

    correct = (callback?: Function, weeks: number = 18) => {
        correctSeason({
            id: this.id, data: {weeks}, callback: (response: ResponseShape) => {
                const success = responseIsSuccess(response);
                if (success)
                    this.initializeContestants(response.data.season.contestants);
                if (callback !== undefined && callback !== null) {
                    callback(this, success, response);
                }
            }
        });
    };

    setField = (name: string, value: any) => {
        this.fields[name] = value;
    };

    private initializeFields = () => {
        this.initializeField('id');
        this.initializeField('label');
        this.initializeField('status');
        this.initializeField('key');
        this.initializeContestants();
    };

    private initializeContestants = (contestants?: any) => {
        contestants = contestants || this.props.contestants;
        if (contestants !== undefined && contestants !== null && Array.isArray(contestants))
            this.contestants = contestants.map(contestant => new Contestant(contestant));
    };

    private initializeField = (name: string, propName: string = '', acceptNull: boolean = false) => {
        const value = isNull(propName) ? this.props[name] : this.props[propName];
        if (acceptNull || isNotNull(value)) this.fields[name] = value;
    };
}