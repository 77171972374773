import './style/contestantsOverview.scss';

import React, {MouseEventHandler} from 'react';

import {
    AwesomeIcon,
    ChangeFunction,
    DvrdButton,
    DvrdSelect,
    Loader,
    SelectItemShape,
    WithBackground
} from '@dvrd/dvr-controls';

import {Season} from "../../models/seasonModel";
import {ScoreShape} from "../../util/interfaces";
import {hasHover, isNull} from "../../util/utils";
import {Contestant} from "../../models/contestantModel";
import {AppContext} from "../context/appContext";

interface ViewProps {
    onClickContestant: Function,
    onClickDelete: Function,
    onClickAdd: MouseEventHandler;
    onClickAddFromSeason: MouseEventHandler;
    onSelectSeason: ChangeFunction;
    onSelectSeasonContestant: ChangeFunction;
    onSubmitContestant: MouseEventHandler;
    onCloseSelectFromSeason: any,
    contestants: Contestant[],
    seasons: Season[],
    selectedSeason: string,
    selectedContestant: string,
    loading: boolean,
    selectingFromSeason: boolean,
    loadingSeasons: boolean,
}

export class ContestantsOverview extends React.Component<ViewProps> {
    declare context: React.ContextType<typeof AppContext>;
    static contextType = AppContext;
    dialog: HTMLDivElement | null = null;

    onCloseSelectFromSeason = () => {
        if (this.dialog === null || !hasHover(this.dialog))
            this.props.onCloseSelectFromSeason();
    };

    getSeasonItems = (): SelectItemShape[] => this.props.seasons.map(season => ({
        label: season.label,
        value: season.id
    }));

    getContestantItems = (): SelectItemShape[] => {
        const {selectedSeason, seasons} = this.props;
        for (const season of seasons) {
            if (season.id === selectedSeason) return season.contestants.map(contestant => ({
                label: contestant.fullName,
                value: contestant.id
            }));
        }
        return [];
    };

    getTotalScore = (contestant: Contestant): string => {
        return contestant.scores.reduce((value: number, score: ScoreShape) => value + score.value, 0).toString();
    };

    getTotalMars = (contestant: Contestant): string => {
        return contestant.mars.reduce((value: number, score: ScoreShape) => value + score.value, 0).toString();
    };

    renderTable = (): React.ReactNode => {
        return (
            <div className='contestantsTable'>
                <Loader active={this.props.loading}/>
                {this.renderTableHeader()}
                {this.renderTableBody()}
            </div>
        )
    };

    renderTableHeader = (): React.ReactNode => {
        return (
            <div className='row header'>
                <div className='cell number'>
                    <label className='cellValue'>#</label>
                </div>
                <div className='cell fullName'>
                    <label className='cellValue'>Naam</label>
                </div>
                <div className='cell email'>
                    <label className='cellValue'>E-mailadres</label>
                </div>
                <div className='cell score'>
                    <label className='cellValue'>Totaal score</label>
                </div>
                <div className='cell mars'>
                    <label className='cellValue'>Totaal marsen</label>
                </div>
                <div className='cell action'>
                    <label className='cellValue'>Actie</label>
                </div>
            </div>
        )
    };

    renderTableBody = (): React.ReactNode => {
        const {contestants, onClickContestant, onClickDelete} = this.props;
        return contestants.map(contestant => (
            <div key={contestant.id} className='row' onClick={onClickContestant(contestant)}>
                <div className='cell number'>
                    <label className='cellValue'>{contestant.paddedNumber} )</label>
                </div>
                <div className='cell fullName'>
                    <label className='cellValue'>{contestant.fullName}</label>
                </div>
                <div className='cell email'>
                    <label className='cellValue'>{contestant.email}</label>
                </div>
                <div className='cell score'>
                    <label className='cellValue'>{this.getTotalScore(contestant)}</label>
                </div>
                <div className='cell mars'>
                    <label className='cellValue'>{this.getTotalMars(contestant)}</label>
                </div>
                <div className='cell action'>
                    <div className='cellValue'>
                        <AwesomeIcon name='edit' className='editButton' onClick={onClickContestant(contestant)}/>
                        <AwesomeIcon name='trash-alt' className='deleteButton' onClick={onClickDelete(contestant)}/>
                    </div>
                </div>
            </div>
        ))
    };

    renderSelectFromSeason = (): React.ReactNode => {
        const {
            selectingFromSeason, onCloseSelectFromSeason, selectedContestant, selectedSeason,
            onSelectSeason, onSelectSeasonContestant, onSubmitContestant, loadingSeasons
        } = this.props;
        if (selectingFromSeason) return (
            <WithBackground active onClose={this.onCloseSelectFromSeason}>
                <div className='selectFromSeasonContainer' ref={ref => {
                    this.dialog = ref
                }}>
                    <div className='headerContainer'>
                        <label className='headerTitle'>Deelnemer selecteren</label>
                        <span className='common-icon-cross closeButton' onClick={onCloseSelectFromSeason}/>
                    </div>
                    <div className='bodyContainer'>
                        <Loader active={loadingSeasons}/>
                        <p className='description'>Selecteer een seizoen en deelnemer om deze toe te voegen aan het
                            huidige seizoen.</p>
                        <div className='selectionContainer'>
                            <DvrdSelect value={selectedSeason} items={this.getSeasonItems()}
                                        onChange={onSelectSeason} label='Seizoen' optionsContainerHeight='20rem'/>
                            <DvrdSelect value={selectedContestant} items={this.getContestantItems()}
                                        onChange={onSelectSeasonContestant} label='Deelnemer'
                                        optionsContainerHeight='30rem'/>
                        </div>
                    </div>
                    <div className='footerContainer'>
                        <DvrdButton onClick={onCloseSelectFromSeason} label='Annuleren'/>
                        <DvrdButton onClick={onSubmitContestant} label='Opslaan'
                                    disabled={loadingSeasons || isNull(selectedContestant)}/>
                    </div>
                </div>
            </WithBackground>
        );
        return null;
    };

    render = () => {
        const {onClickAdd, onClickAddFromSeason} = this.props;
        return (
            <>
                {this.renderSelectFromSeason()}
                <div className='contestantsOverview'>
                    <label className='title'>Deelnemers aan dit seizoen</label>
                    {this.renderTable()}
                    <div className='actionsContainer'>
                        <DvrdButton onClick={onClickAdd} label='Nieuwe aanmaken' ornaments={{element: <span>+</span>}}/>
                        <DvrdButton onClick={onClickAddFromSeason} label='Toevoegen uit ander seizoen'
                                    ornaments={{element: <span>+</span>}}/>
                    </div>
                </div>
            </>
        )
    };
}