import * as React from "react";
import {RouteObject} from "react-router";
import {LoginController} from "./components/auth/login/loginController";
import {SeasonDetailController} from "./components/season/detail/seasonDetailController";
import {SeasonsOverviewController} from "./components/season/seasonsOverviewController";
import {ContestantsOverviewController} from "./components/contestant/contestantsOverviewController";
import PdfViewer from "./components/misc/pdfViewer";
import {GenerateSchemesController} from "./components/schemes/generateSchemesController";
import {AdminController} from "./components/admin/adminController";
import App from "./app";
import AppRoute from "./components/misc/appRoute";
import {Navigate} from "react-router-dom";

export const Routes: Array<RouteObject> = [
    {
        path: '*', element: <App/>, children: [
            {path: 'inloggen', element: <AppRoute><LoginController/></AppRoute>},
            {
                path: 'seizoen',
                element: <AppRoute><SeasonDetailController/></AppRoute>
            },
            {
                path: 'seizoenen',
                element: <AppRoute><SeasonsOverviewController/></AppRoute>,
            },
            {
                path: 'deelnemers',
                element: <AppRoute authenticated><ContestantsOverviewController/></AppRoute>,
            },
            {
                path: 'schema',
                element: <AppRoute authenticated><GenerateSchemesController/></AppRoute>,
            },
            {
                path: 'pdf-document',
                element: <AppRoute authenticated><PdfViewer/></AppRoute>,
            },
            {
                path: 'admin',
                element: <AppRoute authenticated><AdminController/></AppRoute>,
            },
            {path: '*', element: <Navigate to='/inloggen'/>},
        ]
    }
];