import * as React from 'react';
import {useContext, useRef} from 'react';
import classNames from 'classnames';

import {Button, Dialog, DialogActionShape, DvrdButton, useEvent} from '@dvrd/dvr-controls';
import {ScoreDetailsController} from "../season/scores/scoreDetailsController";
import {EditSeasonController} from "../season/edit/editSeasonController";
import {ContestantDetailController} from "../contestant/detail/contestantDetailController";
import {ChangePassword} from "./changePassword";
import {UserDetailController} from "../admin/detail/userDetailController";
import {AppContext} from "../context/appContext";
import {useLocation, useNavigate} from "react-router-dom";

interface DialogDataShape {
    message: string,
    title?: string,
    actions?: DialogActionShape[],
    transparent?: boolean,
    persistent?: boolean,
}

export default function PopupController() {
    const {user} = useContext(AppContext).userContext;
    const dialogRef = useRef<Dialog>(null);
    const location = useLocation();
    const navigate = useNavigate();
    useEvent('onOpenDialog', onOpenDialog);

    function onOpenDialog(data: DialogDataShape) {
        const dialog = dialogRef.current;
        if (dialog) dialog.onOpen(data);
        else alert(data.message);
    }

    function onClickBack() {
        navigate(-1);
    }

    function renderUserIndependentComponents() {
        return (
            <>
                <Dialog ref={dialogRef}/>
                {location.pathname !== '/inloggen' &&
                    <DvrdButton onClick={onClickBack} label='Terug'
                                className={classNames('appBackButton', user === null && 'top')}/>}
            </>
        )
    }

    function renderUserDependentComponents() {
        if (user !== null && user.id !== null)
            return (
                <>
                    <ScoreDetailsController/>
                    <EditSeasonController/>
                    <ContestantDetailController/>
                    <ChangePassword/>
                    <UserDetailController/>
                </>
            );
        return null;
    }

    return (
        <>
            {renderUserDependentComponents()}
            {renderUserIndependentComponents()}
        </>
    );
}