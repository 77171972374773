import React from 'react';

import {DialogActionShape, DvrdInput, DvrdSelect, InlineDialog, SelectItemShape} from '@dvrd/dvr-controls';

import {enterPressed, navigate, setPageTitle, toMoment} from "../../util/utils";
import {GenerateSchemes} from "./generateSchemes";
import {TableSorting} from "../../util/interfaces";
import {Contestant} from "../../models/contestantModel";
import {AppContext} from "../context/appContext";


interface State {
    schemeAmount: string,
    totalSorting: TableSorting,
    firstWeek: string,
    dialogOpen: boolean,
    dialogTitle: string,
    dialogMessage: string,
    dialogActions: DialogActionShape[] | DialogActionShape | null,
    dialogContent: React.ReactElement | null,
    contestant: Contestant | null,
}

export interface Scheme {
    label: string,
    img: string,
    docName: string,
    onClick: any,
}

export class GenerateSchemesController extends React.Component<{}, State> {
    declare context: React.ContextType<typeof AppContext>;
    static contextType = AppContext;
    state = {
        schemeAmount: '',
        totalSorting: TableSorting.NUMBER,
        firstWeek: (toMoment('').week() + 1).toString(),
        dialogOpen: false,
        dialogTitle: '',
        dialogMessage: '',
        dialogActions: null,
        dialogContent: null,
        contestant: null,
    };

    contestant: string = '';

    onKeyDownAmount = (evt: React.KeyboardEvent) => {
        if (enterPressed(evt)) this.onEnterAmount();
        else if (!/\d|Backspace|ArrowLeft|ArrowRight/.test(evt.key))
            evt.preventDefault();
    };

    onEnterAmount = () => {
        this.onCloseDialog();
        this.onNavigatePlayScheme();
    };

    onChangeSchemeAmount = (value: string) => {
        this.setState({schemeAmount: value});
    };

    onChangeSorting = (value: TableSorting) => {
        this.setState({totalSorting: value});
    };

    onChangeWeek = (value: string) => {
        this.setState({firstWeek: value});
    };

    onClickPlayScheme = () => {
        const {schemeAmount} = this.state;
        this.onOpenDialog('Kies een deelnemer en het aantal schema\'s dat gemaakt moet worden.', 'Speelschema', [{label: 'Sluiten'}, {
            label: 'Oke',
            onClick: this.onNavigatePlayScheme
        }], <>
            <DvrdSelect value={this.contestant} items={this.getContestantItems()} unControlled
                        onChange={this.onSelectContestant} label='Deelnemer' optionsContainerHeight='20rem'/>
            <DvrdInput value={schemeAmount} onChange={this.onChangeSchemeAmount} label='Aantal (standaard 4)'
                       onKeyDown={this.onKeyDownAmount} unControlled fullWidth/>
        </>)
    };

    onSelectContestant = (id: string) => {
        this.contestant = id;
    };

    onClickTotal = () => {
        const {totalSorting} = this.state;
        this.onOpenDialog('Hoe moet het schema gesorteerd worden?', 'Totaalscore', [{label: 'Sluiten'}, {
            label: 'Oke',
            onClick: this.onNavigateTotalScheme,
        }], <DvrdSelect value={totalSorting} items={this.getSortItems()} label='Sortering'
                        onChange={this.onChangeSorting} unControlled selectOnly/>);
    };

    onClickRoundScheme = () => {
        navigate('/pdf-document?doc=ronde');
    };

    onClickPresence = () => {
        const {firstWeek} = this.state;
        this.onOpenDialog('Wat is de eerste week voor dit schema?', 'Aanwezigheid', [{label: 'Sluiten'}, {
            label: 'Oke',
            onClick: this.onNavigatePresenceScheme,
        }], <DvrdSelect value={firstWeek} items={this.getWeekItems()} label='Eerste week'
                        onChange={this.onChangeWeek} optionsContainerHeight='20rem' unControlled selectOnly/>);
    };

    onNavigateTotalScheme = () => {
        navigate(`/pdf-document?doc=totaalscore&sort=${this.state.totalSorting}`);
    };

    onNavigatePlayScheme = () => {
        const contestantNumber = this.getContestantNumber();
        navigate(`/pdf-document?doc=speelschema&aantal=${this.state.schemeAmount}&deelnemer=${this.contestant}&nummer=${contestantNumber}`);
    };

    onNavigatePresenceScheme = () => {
        navigate(`/pdf-document?doc=aanwezigheid&week=${this.state.firstWeek}`);
    };

    onOpenDialog = (message: string, title: string, actions: DialogActionShape[] | DialogActionShape | null = null,
                    content: React.ReactElement | null = null) => {
        this.setState({
            dialogMessage: message,
            dialogTitle: title,
            dialogActions: actions,
            dialogContent: content
        }, () => {
            this.setState({dialogOpen: true});
        })
    };

    onCloseDialog = () => {
        this.setState({dialogOpen: false});
    };

    getContestantItems = (): SelectItemShape[] => {
        const {season} = this.context.seasonContext,
            contestants: Contestant[] = season === null ? [] : season.contestants;
        const items = contestants.map(contestant => ({
            label: `${contestant.paddedNumber} ${contestant.fullName}`,
            value: `${contestant.paddedNumber} ${contestant.fullName}`
        }));
        items.sort((itemA, itemB) => itemA.label.toLowerCase().localeCompare(itemB.label.toLowerCase()));
        return [{label: 'Leeg schema', value: ''}].concat(items);
    };

    getContestantNumber = (): string => {
        if (!this.contestant) return '';
        const {season} = this.context.seasonContext,
            contestants: Contestant[] = season === null ? [] : season.contestants;
        for (const contest of contestants) {
            if (this.contestant === `${contest.paddedNumber} ${contest.fullName}`)
                return contest.paddedNumber;
        }
        return '';
    }

    getSortItems = (): SelectItemShape[] => [
        {label: 'Nummer', value: TableSorting.NUMBER},
        {label: 'Punten', value: TableSorting.SCORES},
        {label: 'Marsen', value: TableSorting.MARS},
        {label: 'Naam', value: TableSorting.NAME},
    ];

    getWeekItems = (): SelectItemShape[] => {
        const weeks: SelectItemShape[] = [];
        for (let i = 1; i <= 52; i++)
            weeks.push({label: `Week ${i}`, value: i.toString()});
        return weeks;
    };

    getDocuments = (): Scheme[] => {
        const schemes = [
            {
                label: 'Speelschema',
                img: require('../../../resource/speelschema.png'),
                docName: 'speelschema',
                onClick: this.onClickPlayScheme
            },
        ];
        if (this.context.seasonContext.season !== null) {
            schemes.push({
                label: 'Totaalscore',
                img: require('../../../resource/totaalscore.png'),
                docName: 'totaalscore',
                onClick: this.onClickTotal
            });
            schemes.push({
                label: 'Rondescores',
                img: require('../../../resource/rondescore.png'),
                docName: 'ronde',
                onClick: this.onClickRoundScheme,
            });
            schemes.push({
                label: 'Aanwezigheid',
                img: require('../../../resource/aanwezigheid.png'),
                docName: 'aanwezigheid',
                onClick: this.onClickPresence,
            });
        }
        return schemes;
    };

    componentDidMount = () => {
        setPageTitle('Schema\'s');
    };

    render = () => {
        const {dialogTitle, dialogMessage, dialogActions, dialogContent, dialogOpen} = this.state;
        return (
            <>
                <InlineDialog actions={dialogActions} content={dialogContent} message={dialogMessage} open={dialogOpen}
                              title={dialogTitle} onClose={this.onCloseDialog} containerClass='schemesDialog'/>
                <GenerateSchemes documents={this.getDocuments()}/>
            </>
        );
    };
}