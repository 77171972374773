import './style/scoreDetails.scss';

import React, {MouseEventHandler} from 'react';
import classNames from 'classnames';

import {DvrdButton, WithBackground} from '@dvrd/dvr-controls';

import {Season} from "../../../models/seasonModel";
import {ScoreDetailMode} from "../../../util/interfaces";
import {enterPressed} from "../../../util/utils";
import {Contestant} from "../../../models/contestantModel";

interface ViewProps {
    onChangeScore: Function,
    onChangeMode: Function, // Scores / mars
    onSubmit: MouseEventHandler;
    onClose: MouseEventHandler;
    season: Season,
    mode: ScoreDetailMode,
    loading: boolean,
}

interface ViewState {
    hoverOn: {
        contestant: string,
        week: number,
    }
}

export class ScoreDetails extends React.Component<ViewProps, ViewState> {
    state = {
        hoverOn: {
            contestant: '',
            week: -1,
        }
    };

    blurTimeout: number | null = null;

    onSpanKey = (evt: React.KeyboardEvent) => {
        if (enterPressed(evt)) {
            evt.preventDefault();
            (evt.target as HTMLSpanElement).blur();
        } else if (!/[\d]/.test(evt.key) && !['Backspace', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(evt.key))
            evt.preventDefault();
    };

    onMouseEnterCell = (contestant: string, week: number) => () => {
        if (this.blurTimeout !== null) {
            window.clearTimeout(this.blurTimeout);
            this.blurTimeout = null;
        }
        this.setState({hoverOn: {contestant, week}});
    };

    onMouseLeaveCell = () => {
        this.blurTimeout = window.setTimeout(() => {
            this.setState({hoverOn: {contestant: '', week: -1}});
        }, 100);
    };

    onFocusScore = (evt: any) => {
        const {target} = evt, range = document.createRange(), selection = getSelection();
        if (selection !== null) {
            range.selectNodeContents(target);
            selection.removeAllRanges();
            selection.addRange(range);
        }
    };

    getContestantWeekValue = (contestant: Contestant, week: number): number => {
        const {mode} = this.props, scores = mode === ScoreDetailMode.SCORES ? contestant.scores : contestant.mars;
        for (const score of scores)
            if (score.week === week && score.value) return score.value;
        return 0;
    };

    getMaxWeeks = (): number => {
        const {season, mode} = this.props, contestants = season.contestants;
        let max = 18;
        switch (mode) {
            case ScoreDetailMode.MARS:
                for (const contestant of contestants)
                    for (const score of contestant.mars)
                        if (score.week > max)
                            max = score.week;
                break;
            case ScoreDetailMode.SCORES:
                for (const contestant of contestants)
                    for (const score of contestant.scores)
                        if (score.week > max)
                            max = score.week;
                break;
        }
        return max + 1;
    };

    renderHeader = (): React.ReactNode => {
        const {season, onClose} = this.props;
        return (
            <div className='headerContainer'>
                <label className='headerTitle'>Puntentelling seizoen {season.label}</label>
                <span className='common-icon-cross closeButton' onClick={onClose}/>
            </div>
        )
    };

    renderBody = (): React.ReactNode => {
        return (
            <div className='bodyContainer'>
                {this.renderOptions()}
                {this.renderTable()}
            </div>
        )
    };

    renderOptions = (): React.ReactNode => {
        const {mode, onChangeMode} = this.props;
        return (
            <div className='optionsContainer'>
                <div className={classNames('optionContainer', 'left', mode === ScoreDetailMode.SCORES && 'active')}
                     onClick={onChangeMode(ScoreDetailMode.SCORES)}>
                    <label className='optionLabel'>Punten</label>
                </div>
                <div className={classNames('optionContainer', 'right', mode === ScoreDetailMode.MARS && 'active')}
                     onClick={onChangeMode(ScoreDetailMode.MARS)}>
                    <label className='optionLabel'>Marsen</label>
                </div>
            </div>
        )
    };

    renderTable = (): React.ReactNode => {
        return (
            <div className='tableContainer'>
                {this.renderTableHeader()}
                {this.renderTableBody()}
            </div>
        );
    };

    renderTableHeader = (): React.ReactNode => {
        const {hoverOn} = this.state, weeks = [];
        for (let i = 1; i <= this.getMaxWeeks(); i++)
            weeks.push(i);
        return (
            <div className='tableHeader'>
                <div className='row left'>
                    <div className='cell contestant'>
                        <label className='cellValue'>Deelnemer</label>
                    </div>
                </div>
                <div className='row right'>
                    {weeks.map((week: number, key: any) => (
                        <div key={key} className={classNames('cell week', hoverOn.week === week && 'hovering')}>
                            <label className='cellValue'>Week {week}</label>
                        </div>
                    ))}
                </div>
            </div>
        )
    };

    renderTableBody = (): React.ReactNode => {
        const {season, onChangeScore} = this.props, contestants = season.contestants.slice(),
            weeks: number[] = [], {hoverOn} = this.state;
        contestants.sort((contestantA, contestantB) => contestantA.number - contestantB.number);
        for (let i = 1; i <= this.getMaxWeeks(); i++)
            weeks.push(i);
        return (
            <div className='tableBody'>
                <div className='body left'>
                    {contestants.map(contestant => (
                        <div key={contestant.id} className='row left'>
                            <div
                                className={classNames('cell contestant', hoverOn.contestant === contestant.id && 'hovering')}>
                                <label className='cellValue'>{contestant.validNumber} {contestant.fullName}</label>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='body right'>
                    {contestants.map((contestant) => (
                        <div key={contestant.id} className='row'>
                            {weeks.map((week, key: any) => (
                                <div key={key} className='cell week'
                                     onMouseEnter={this.onMouseEnterCell(contestant.id, week)}
                                     onMouseLeave={this.onMouseLeaveCell}>
                                    <span contentEditable onInput={onChangeScore(contestant, week)} tabIndex={1}
                                          className='cellValue' onKeyDown={this.onSpanKey} onFocus={this.onFocusScore}
                                          dangerouslySetInnerHTML={{__html: this.getContestantWeekValue(contestant, week).toString()}}/>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    renderFooter = (): React.ReactNode => {
        const {onSubmit, onClose} = this.props;
        return (
            <div className='footerContainer'>
                <DvrdButton onClick={onSubmit} label='Opslaan' className='submitButton'/>
                <DvrdButton onClick={onClose} label='Sluiten' secondary/>
            </div>
        )
    };

    render = () => {
        const {onClose} = this.props;
        return (
            <WithBackground active onClose={onClose}>
                <div className='scoreDetailsView'>
                    {this.renderHeader()}
                    {this.renderBody()}
                    {this.renderFooter()}
                </div>
            </WithBackground>
        )
    }
}