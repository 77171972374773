import * as React from 'react';
import {Season} from "../../../models/seasonModel";
import {EditSeason} from "./editSeason";
import {addCustomEventListener, removeCustomEventListener} from "../../../util/eventDispatcher";
import {EditType, EventType} from "../../../util/interfaces";
import {ResponseShape} from "../../../util/requestSender";
import {onSeasonEvent} from "../../../util/utils";
import {Contestant} from "../../../models/contestantModel";
import { showDialog } from '@dvrd/dvr-controls';

interface ControllerState {
    season: Season,
    seasons: Season[],
    loading: boolean,
    active: boolean,
    selectedSeason: string,
    mode: EditType,

    [index: string]: any,
}

export class EditSeasonController extends React.Component<{}, ControllerState> {
    state = {
        season: new Season({}),
        seasons: new Array<Season>(),
        mode: EditType.EDIT,
        active: false,
        loading: false,
        selectedSeason: '',
    };


    onActivate = (season: Season | null) => {
        this.setState({mode: season === null ? EditType.NEW : EditType.EDIT}, () => {
            if (season === null) season = new Season({label: 'Nieuw seizoen'});
            this.loadSeasons(season);
        });
    };

    onSubmit = () => {
        const {mode, season} = this.state;
        if (mode === EditType.NEW) this.onSave(season);
        else this.onUpdate(season);
    };

    onSave = (season: Season) => {
        const {selectedSeason} = this.state;
        const contestants = this.getContestants(selectedSeason).map(contestant => contestant.id);
        season.save(contestants, (season: Season, success: boolean, response: ResponseShape) => {
            if (success) {
                showDialog('Het seizoen is aangemaakt', 'Seizoen aanmaken');
                onSeasonEvent(EventType.CREATE, season);
                this.onClose();
            } else {
                const {message} = response.data,
                    dialogMessage = message === undefined ? 'Het seizoen aanmaken is mislukt, probeer het later opnieuw of neem contact met ons op.' : message;
                showDialog(dialogMessage, 'Seizoen aanmaken mislukt');
            }
        });
    };

    onUpdate = (season: Season) => {
        season.update((season: Season, success: boolean, response: ResponseShape) => {
            if (success) {
                showDialog('Het seizoen is gewijzigd', 'Seizoen wijzigen');
                onSeasonEvent(EventType.UPDATE, season);
                this.onClose();
            } else {
                const {message} = response.data,
                    dialogMessage = message === undefined ? 'Het seizoen wijzigen is mislukt, probeer het later opnieuw of neem contact met ons op.' : message;
                showDialog(dialogMessage, 'Seizoen aanmaken mislukt');
            }
        });
    };

    onChange = (name: string) => (value: string) => {
        if (name === 'selectedSeason')
            this.setState({[name]: value});
        else {
            const {season} = this.state;
            season.setField(name, value);
            this.setState({season});
        }
    };

    onClose = () => {
        this.setState({
            active: false,
            season: new Season({}),
            loading: false,
            selectedSeason: '',
            mode: EditType.EDIT,
        });
    };

    getContestants = (seasonId: string): Contestant[] => {
        if (seasonId.length === 0) return [];

        const {seasons} = this.state;
        for (const season of seasons)
            if (season.id === seasonId) return season.contestants;
        return [];
    };

    loadSeasons = (season: Season) => {
        if (this.state.mode === EditType.NEW)
            Season.getAll((seasons: Season[]) => {
                this.setState({active: true, seasons, season});
            });
        else
            this.setState({active: true, season});
    };

    componentDidMount = () => {
        addCustomEventListener('EditSeasonController', 'onEditSeason', this.onActivate);
    };

    componentWillUnmount = () => {
        removeCustomEventListener('EditSeasonController', 'onEditSeason');
    };

    render = () => {
        const {season, seasons, selectedSeason, loading, active, mode} = this.state;
        if (!active) return null;
        return <EditSeason onSubmit={this.onSubmit} onChange={this.onChange} onClose={this.onClose}
                           season={(season as Season)} seasons={seasons} selectedSeason={selectedSeason}
                           loading={loading} mode={mode}/>
    };
}