import * as React from 'react'
import {useContext, useEffect, useState} from 'react'
import {Outlet} from "react-router-dom";

import {AppNavigator, Loader} from '@dvrd/dvr-controls';

import {getJwt, onChangePassword} from "./util/utils";
import {User} from "./models/userModel";
import './util/startup';
import {Header} from "./components/header/header";
import PopupController from "./components/misc/popupController";
import {AppContext} from "./components/context/appContext";

export default function App() {
    const context = useContext(AppContext);
    const [userChecked, setUserChecked] = useState(false);

    function tryLogin() {
        const jwt: string | null = getJwt();
        if (jwt !== null)
            User.get((user: User | null, success: boolean) => {
                if (success) {
                    context.onChangeContext({user});
                    if (user !== null && user.status === 'change_password')
                        onChangePassword();
                }
                setUserChecked(true);
            });
        else
            setUserChecked(true);
    }

    useEffect(() => {
        tryLogin();
    }, []);

    return (
        <>
            <AppNavigator/>
            <Loader active={!userChecked}/>
            {userChecked && <>
                <Header/>
                <PopupController/>
                <Outlet/>
            </>}
        </>
    )
}