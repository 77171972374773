import * as React from 'react';
import {User} from "../../../models/userModel";
import {UserDetail} from "./userDetail";
import {EditType, EventType} from "../../../util/interfaces";
import {addCustomEventListener, removeCustomEventListener} from "../../../util/eventDispatcher";
import {ResponseShape} from "../../../util/requestSender";
import {onUserEvent} from "../../../util/utils";
import {showDialog} from "@dvrd/dvr-controls";

interface State {
    user: User,
    loading: boolean,
    active: boolean,
    activate: boolean,
    mode: EditType,
}

export class UserDetailController extends React.Component<{}, State> {
    state = {
        user: new User({}),
        loading: false,
        active: false,
        activate: false,
        mode: EditType.NEW,
    };

    onOpen = (user: User | null) => {
        const mode = user === null ? EditType.NEW : EditType.EDIT;
        if (user === null) user = new User({email: 'Nieuwe gebruiker'});
        this.setState({user, active: true, mode});
    };

    onClose = () => {
        this.setState({active: false, loading: false}, () => {
            this.setState({user: new User({}), activate: false});
        });
    };

    onChange = (key: string) => (value: string) => {
        if (key === 'activate') {
            this.setState({activate: !this.state.activate});
        } else {
            const {user} = this.state;
            user.setField(key, value);
            this.setState({user});
        }
    };

    onSubmit = () => {
        switch (this.state.mode) {
            case EditType.NEW:
                this.onSave();
                break;
            case EditType.EDIT:
                this.onUpdate();
                break;
        }
    };

    onUpdate = () => {
        const {user} = this.state;
        this.setState({loading: true});
        user.update((user: User, success: boolean, response: ResponseShape) => {
            this.setState({loading: false});
            if (success) {
                onUserEvent(EventType.UPDATE, user);
                this.onClose();
            } else {
                const message = response.data.message || 'Het bijwerken van de gebruiker is niet gelukt.';
                showDialog(message, 'Gebruiker bijwerken');
            }
        });
    };

    onSave = () => {
        const {user, activate} = this.state;
        this.setState({loading: true});
        user.save(activate, (user: User, success: boolean, response: ResponseShape) => {
            if (success) {
                onUserEvent(EventType.CREATE, user);
                this.onClose();
            } else {
                const message = response.data.message || 'Het aanmaken van de gebruiker is niet gelukt.';
                showDialog(message, 'Gebruiker aanmaken');
            }
        });
    };

    componentDidMount = () => {
        addCustomEventListener('UserDetailController', 'onOpenUser', this.onOpen);
    };

    componentWillUnmount = () => {
        removeCustomEventListener('UserDetailController', 'onOpenUser');
    };

    render = () => {
        const {user, loading, active, activate, mode} = this.state;
        if (active)
            return (
                <UserDetail onChange={this.onChange} onSubmit={this.onSubmit} onClose={this.onClose} loading={loading}
                            user={user} activate={activate} mode={mode}/>
            );
        return null;
    };
}